import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import AudioPlayer from "react-modern-audio-player";
import "../scss/landingPage.scss";
import { FaPlay, FaPause } from "react-icons/fa";
import {
  FaYoutube,
  FaDiscord,
  FaReddit,
  FaSlack,
  FaTwitter,
} from "react-icons/fa";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Multitrack from "wavesurfer-multitrack";
import {
  FaBookOpen,
  FaTheaterMasks,
  FaCompactDisc,
  FaMicrophone,
  FaVolumeUp,
  FaMusic,
  FaDrumSteelpan,
  FaGuitar,
  FaHeadphones,
} from "react-icons/fa";
import { subscribeToMailerLite } from "../api/service";

const useWindowWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return width;
};

// Modify the playlist definitions
const getTruncatedName = (name, windowWidth) => {
  if (windowWidth < 480) {
    return name.length > 12 ? name.substring(0, 12) + "..." : name;
  }
  return name;
};

function LandingPage() {
  const navigate = useNavigate();
  const [subscribeEmail, setSubscribeEmail] = useState("");
  const [subscribeStatus, setSubscribeStatus] = useState({
    success: false,
    message: "",
  });

  // Use the windowWidth from the custom hook
  const windowWidth = useWindowWidth();
  const [alignmentData, setAlignmentData] = useState([]);
  const [tracks, setTracks] = useState([]);
  const multitrackRef = useRef(null);
  const multitrackContainerRef = useRef(null);

  const [dialogues, setDialogues] = useState([]);
  const [isPlayingMultitrack, setIsPlayingMultitrack] = useState(false);

  const timeIntervalRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(null);
  const [activeTab, setActiveTab] = useState("audio"); // Add this near other useState declarations
  const [isSubscribing, setIsSubscribing] = useState(false);

  const socialLinks = [
    {
      icon: FaReddit,
      url: "https://www.reddit.com/user/audiowizard_ai/",
      className: "reddit",
    },
    {
      icon: FaTwitter,
      url: "https://x.com/audiowizard_ai",
      className: "twitter",
    },
    {
      icon: FaSlack,
      url: "https://team-audiowizard.slack.com/archives/C0846CA3E0Y",
      className: "slack",
    },
    {
      icon: FaDiscord,
      url: "https://discord.gg/ZP6zECSQ",
      className: "discord",
    },
  ];

  const TRACK_LABELS = [
    "The narrator",
    "Farah",
    "Aunt Stacy",
    "Man",
    "Sound Effects",
    "BGM",
  ];

  const [rawText, setRawText] = useState("");

  const playList_1 = [
    {
      name: getTruncatedName("The Iliad", windowWidth),
      writer: "Homer",
      img: "assets/logo_with_white_background.png",
      src: "/assets/audiowizard_iliad.mp3",
      id: 1,
    },
  ];

  const playList_2 = [
    {
      name: getTruncatedName("The Iliad", windowWidth),
      writer: "Homer",
      img: "/assets/librivox.png",
      src: "/assets/human_iliad.mp3",
      id: 1,
    },
  ];

  const playList_3 = [
    {
      name: getTruncatedName("The Iliad", windowWidth),
      writer: "Homer",
      img: "/assets/elevenlabs.png",
      src: "/assets/ai_iliad.mp3",
      id: 1,
    },
  ];

  const handleBackLanding = () => {
    navigate("/");
  };

  // Email validation function
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubscribeToMailerLite = async (e) => {
    e.preventDefault();

    if (!subscribeEmail) {
      setSubscribeStatus({
        success: false,
        message: "Please enter your email",
      });
      return;
    }

    if (!isValidEmail(subscribeEmail)) {
      setSubscribeStatus({
        success: false,
        message: "Please enter a valid email address",
      });
      return;
    }

    setIsSubscribing(true);
    try {
      const response = await subscribeToMailerLite(subscribeEmail);
      if (response.success) {
        setSubscribeStatus({
          success: true,
          message: response.message || "Successfully subscribed!",
        });

        // Store email in localStorage for the demo page
        localStorage.setItem("demoEmail", subscribeEmail);

        // Open demo page in new tab
        window.open("/demo", "_blank");
      } else {
        setSubscribeStatus({
          success: false,
          message: response.message || "Failed to subscribe",
        });
      }
    } catch (error) {
      console.error("Error subscribing to MailerLite:", error);
      setSubscribeStatus({
        success: false,
        message: error.message || "Failed to subscribe",
      });
    } finally {
      setIsSubscribing(false);
    }
  };

  useEffect(() => {
    let defaultHeight = 55;
    let newTracks = [
      {
        id: "narrator",
        url: "/assets/This Summer Will Be Different/narrator.mp3",
        options: {
          waveColor: "violet",
          progressColor: "purple",
          height: defaultHeight,
        },
      },
      {
        id: "aunt stacy",
        url: "/assets/This Summer Will Be Different/aunt stacy.mp3",
        options: {
          waveColor: "yellow",
          progressColor: "black",
          height: defaultHeight,
        },
      },
      {
        id: "farah",
        url: "/assets/This Summer Will Be Different/farah.mp3",
        options: {
          waveColor: "orange",
          progressColor: "green",
          height: defaultHeight,
        },
      },
      {
        id: "man",
        url: "/assets/This Summer Will Be Different/man.mp3",
        options: {
          waveColor: "lightblue",
          progressColor: "blue",
          height: defaultHeight,
        },
      },
      {
        id: "sfx",
        url: "/assets/This Summer Will Be Different/sfx_audio_modified.mp3",
        options: {
          waveColor: "green",
          progressColor: "darkgreen",
          height: defaultHeight,
        },
      },
      {
        id: "bgm",
        url: "/assets/This Summer Will Be Different/modified_background_music.mp3",
        options: {
          waveColor: "orange",
          progressColor: "darkorange",
          height: defaultHeight,
        },
      },
    ];
    setTracks(newTracks);
  }, []);

  // Function to handle sentence click (for audio synchronization)
  const handleSentenceClick = useCallback((startTime) => {
    console.log("handleSentenceClick called with startTime:", startTime);
    if (multitrackRef.current && multitrackRef.current.setTimeToPoint) {
      console.log("Setting time to:", startTime);
      multitrackRef.current.setTimeToPoint(startTime);
    } else {
      console.log("multitrackRef.current is not ready");
    }
  }, []);

  // Function to initialize multitrack
  const initializeMultitrack = useCallback(() => {
    const multitrack = Multitrack.create(tracks, {
      container: multitrackContainerRef.current,
      minPxPerSec: 50,
      cursorWidth: 2,
      cursorColor: "#D72F21",
      trackBackground: "#f0f0f0",
      trackBorderColor: "#ddd",
    });

    multitrackRef.current = multitrack;

    multitrack.on("play", () => {
      console.log("Multitrack playing");
      setIsPlayingMultitrack(true);
    });
    multitrack.on("pause", () => {
      console.log("Multitrack paused");
      setIsPlayingMultitrack(false);
    });
    multitrack.on("finish", () => {
      console.log("Multitrack finished");
      setIsPlayingMultitrack(false);
    });

    multitrackRef.current.setTimeToPoint = (time) => {
      multitrack.setTime(time);
    };

    return () => {
      multitrack.destroy();

      // Stop logging when destroyed
      if (timeIntervalRef.current) {
        clearInterval(timeIntervalRef.current);
      }
    };
  }, [tracks]);

  // Function to render track labels
  const renderTrackLabels = useCallback(() => {
    let visibleLabels = TRACK_LABELS;
    return visibleLabels.map((label, index) => (
      <div key={index} className="audio-label">
        {label}
      </div>
    ));
  }, []);

  // Main effect for handling step changes and data loading
  useEffect(() => {
    const fetchDialogues = async () => {
      try {
        console.log("Starting to fetch dialogues..."); // Debug log
        const response = await fetch(
          "/assets/This Summer Will Be Different/character_dialogues.json",
        );
        const data = await response.json();
        console.log("Fetched dialogues:", data);
        setDialogues(data);
      } catch (error) {
        console.error("Error loading dialogues:", error);
      }
    };

    const fetchAlignmentDataAndMultitrack = async () => {
      console.log("Starting to fetch alignment data..."); // Debug log
      try {
        const alignmentFiles = Array.from({ length: 41 }, (_, i) => i).map(
          (i) =>
            fetch(
              `/assets/This Summer Will Be Different/${i}_split_sentence_alignment_post_process_v2.json`,
            ).then((res) => res.json()),
        );
        console.log("Waiting for all alignment files to load..."); // Debug log
        const alignmentData = await Promise.all(alignmentFiles);

        // Validate that we have all the required alignment data
        if (
          alignmentData.some((data) => !data || Object.keys(data).length === 0)
        ) {
          console.log(
            "Some alignment data is missing or empty, retrying in 1 second...",
          );
          setTimeout(fetchAlignmentDataAndMultitrack, 1000);
          return;
        }

        console.log(
          `Successfully loaded ${alignmentData.length} alignment files`,
        ); // Debug log
        setAlignmentData(alignmentData);

        // Only initialize multitrack after alignment data is loaded and valid
        if (
          multitrackContainerRef.current &&
          !multitrackRef.current &&
          tracks.length > 0
        ) {
          console.log("Initializing multitrack..."); // Debug log
          initializeMultitrack();
        } else {
          console.log("Skipping multitrack initialization:", {
            // Debug log
            hasContainer: !!multitrackContainerRef.current,
            hasMultitrack: !!multitrackRef.current,
            tracksLength: tracks.length,
          });
        }
      } catch (error) {
        console.error("Error loading alignment data:", error);
        console.log("Retrying alignment data fetch in 1 second...");
        setTimeout(fetchAlignmentDataAndMultitrack, 1000);
      }
    };

    console.log("Starting data fetch process..."); // Debug log
    const fetchData = async () => {
      await fetchDialogues();
      await fetchAlignmentDataAndMultitrack();
    };
    fetchData();
  }, []);

  // Effect to reinitialize multitrack when tracks change
  useEffect(() => {
    if (
      multitrackContainerRef.current &&
      activeTab === "audio" &&
      alignmentData.length > 0
    ) {
      console.log("Reinitializing multitrack from tracks effect");
      if (multitrackRef.current) {
        multitrackRef.current.destroy();
      }
      initializeMultitrack();
    }
  }, [tracks, activeTab, alignmentData, initializeMultitrack]);

  // Add effect to load raw text
  useEffect(() => {
    const fetchRawText = async () => {
      try {
        // const response = await fetch('/assets/This Summer Will Be Different.txt');
        const response = await fetch(
          "/assets/This Summer Will Be Different/raw_text.txt",
        );
        const text = await response.text();
        setRawText(text);
      } catch (error) {
        console.error("Error loading raw text:", error);
      }
    };
    fetchRawText();
  }, []);

  const isCurrentSentenceAudioPlaying = useCallback(
    (sentenceStartTime, sentenceEndTime) => {
      return (
        currentTime !== null &&
        currentTime >= sentenceStartTime &&
        currentTime <= sentenceEndTime
      );
    },
    [currentTime],
  );

  // Function to handle play/pause for multitrack
  const handlePlayPauseMultitrack = useCallback(() => {
    console.log("Play/Pause clicked");
    if (multitrackRef.current) {
      if (multitrackRef.current.isPlaying()) {
        console.log("Attempting to pause multitrack");
        multitrackRef.current.pause();
        setIsPlayingMultitrack(false);

        // Stop logging when paused
        if (timeIntervalRef.current) {
          clearInterval(timeIntervalRef.current);
        }
      } else {
        console.log("Attempting to play multitrack");
        multitrackRef.current.play();
        setIsPlayingMultitrack(true);
        console.log(currentTime);

        // Start logging current time
        if (timeIntervalRef.current) {
          clearInterval(timeIntervalRef.current);
        }
        timeIntervalRef.current = setInterval(() => {
          console.log("Interval callback executed");
          const currentTime = multitrackRef.current.getCurrentTime();
          setCurrentTime(currentTime);
          console.log(`Current play time: ${currentTime.toFixed(2)} seconds`);
        }, 100); // Update current time every 100ms
      }
    } else {
      console.log("multitrackRef.current is null");
    }
  }, []);

  // Update the ref declarations
  const multitrackTextContainerRef = useRef(null);
  const scrollToCurrentSentence = useCallback((element) => {
    if (!element || !multitrackTextContainerRef.current) return;

    const container = multitrackTextContainerRef.current;
    const elementRect = element.getBoundingClientRect();

    const scrollTop =
      element.offsetTop -
      container.offsetTop -
      container.clientHeight / 2 +
      elementRect.height / 2;

    container.scrollTo({
      top: scrollTop,
      behavior: "smooth",
    });
  }, []);

  const renderScreenplayWithClickableSentences = useCallback(() => {
    if (!dialogues.length || !alignmentData.length) return null;

    return (
      <div className="screenplay" ref={multitrackTextContainerRef}>
        {dialogues.map((dialogue, dialogueIndex) => {
          const [character, _] = Object.entries(dialogue)[0];
          const currentAlignmentData = alignmentData[dialogueIndex] || [];

          // Skip rendering this dialogue if alignment data is missing or empty
          if (!currentAlignmentData || currentAlignmentData.length === 0) {
            console.log(
              `Skipping dialogue ${dialogueIndex} due to missing alignment data`,
            );
            return null;
          }

          let content;
          content = currentAlignmentData.map((item, localSentenceIndex) => {
            const isCurrentlyPlaying = isCurrentSentenceAudioPlaying(
              item.start_time,
              item.end_time,
            );

            // If this sentence is currently playing, scroll to it
            // NOTE: comment this code to disable auto-scrolling
            // if (isCurrentlyPlaying) {
            //   const element = document.getElementById(
            //     `sentence-${dialogueIndex}-${localSentenceIndex}`,
            //   );
            //   requestAnimationFrame(() => scrollToCurrentSentence(element));
            // }

            return (
              <span
                id={`sentence-${dialogueIndex}-${localSentenceIndex}`}
                key={`${dialogueIndex}-${localSentenceIndex}`}
                className={`split-sentence clickable ${
                  isCurrentlyPlaying ? "current-sentence" : ""
                }`}
                onClick={() => {
                  handleSentenceClick(item.start_time);
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                {item.sentence}
              </span>
            );
          });

          return (
            <div key={dialogueIndex} className="dialogue-item">
              <span className="character">
                {character.endsWith(":") ? character : `${character}:`}
              </span>
              {content}
            </div>
          );
        })}
      </div>
    );
  }, [
    dialogues,
    alignmentData,
    handleSentenceClick,
    isCurrentSentenceAudioPlaying,
    scrollToCurrentSentence,
  ]);

  return (
    <div className="landing-page">
      <div className="header-container">
        <img
          className="product-title"
          onClick={handleBackLanding}
          src="/audiowizard.png"
        />
        <div className="top-social-icons">
          {socialLinks.map(({ icon: Icon, url, className }) => (
            <a
              key={url}
              className={className}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon />
            </a>
          ))}
        </div>
      </div>
      <br></br>
      <div className="center-container">
        <div className="content-wrapper">
          <p className="headline">
            Generate high quality audiodramas and audiobooks with AI
          </p>
          <p className="paragraph">
            Transform any books into audiodramas or multicast audiobooks with
            sound effect and background music.
          </p>
        </div>

        <div className="waitlist-section">
          <h2 className="paragraph">
            Try with AudioWizard demo! Please enter your email below.
          </h2>
          <form
            onSubmit={handleSubscribeToMailerLite}
            className="waitlist-form"
          >
            <input
              type="email"
              placeholder="Enter your email"
              className="waitlist-input"
              value={subscribeEmail}
              onChange={(e) => setSubscribeEmail(e.target.value)}
              disabled={isSubscribing}
            />
            <button
              type="submit"
              className="waitlist-button"
              disabled={isSubscribing}
            >
              {isSubscribing ? "Starting Demo..." : "Start Demo"}
            </button>
          </form>
          <p className="disclaimer-text">
            By clicking 'Start Demo,' you agree to receive emails from
            AudioWizard about updates and promotions. You can unsubscribe
            anytime.
          </p>
          {subscribeStatus.message && (
            <div
              className={`submit-status ${subscribeStatus.success ? "success" : "error"}`}
            >
              {subscribeStatus.message}
            </div>
          )}
        </div>
      </div>

      <div className="compare-message">
        <span className="transform">Transform</span>
        <span className="text">text</span>
        <span className="into">into</span>
        <span className="premium">premium</span>
        <span className="audiobook">audiobook—</span>
        <span className="faster">10x</span>
        <span className="faster-text">faster,</span>
        <span className="better">10x better.</span>
      </div>

      <div className="feature-section-container">
        <div className="feature-section">
          <div className="feature-content">
            <h2 className="feature-title">Multicast</h2>
            <p className="feature-description">
              Automatic distinct voices assignment to each character with
              cinematic-style performances.
            </p>
            <h2 className="feature-title">Sound Effects</h2>
            <p className="feature-description">
              Tailored sound effects, enriching scenes with sounds that bring
              stories to life.
            </p>
            <h2 className="feature-title">Background Music</h2>
            <p className="feature-description">
              Expressive automatic background music adapting to the tone and
              mood of each scene.
            </p>
          </div>
          <div className="feature-comparison-wrapper">
            <h2 className="feature-title-right">AudioWizard AI</h2>
            <div className="feature-comparison">
              <AudioPlayer
                playList={playList_1}
                activeUI={{
                  playButton: true,
                  prevNnext: false,
                  volume: false,
                  volumeSlider: false,
                  repeatType: false,
                  trackTime: false,
                  trackInfo: true,
                  artwork: windowWidth >= 480,
                  progress: false,
                  playList: false,
                }}
                rootContainerProps={{
                  width: "100%",
                }}
              />
            </div>
            <h2 className="feature-title-right">LibriVox Human Narrator</h2>
            <div className="feature-comparison">
              <AudioPlayer
                playList={playList_2}
                activeUI={{
                  playButton: true,
                  prevNnext: false,
                  volume: false,
                  volumeSlider: false,
                  repeatType: false,
                  trackTime: false,
                  trackInfo: true,
                  artwork: windowWidth >= 480,
                  progress: false,
                  playList: false,
                }}
                rootContainerProps={{
                  width: "100%",
                }}
              />
            </div>
            <h2 className="feature-title-right">ElevenLabs AI Narrator</h2>
            <div className="feature-comparison">
              <AudioPlayer
                playList={playList_3}
                activeUI={{
                  playButton: true,
                  prevNnext: false,
                  volume: false,
                  volumeSlider: false,
                  repeatType: false,
                  trackTime: false,
                  trackInfo: true,
                  artwork: windowWidth >= 480,
                  progress: false,
                  playList: false,
                }}
                rootContainerProps={{
                  width: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="tap-message">
        <span className="tap">Tap,</span>
        <span className="tap">Tap</span>
        <span className="and">&</span>
        <span className="enjoy">Enjoy!</span>
      </div>

      <div className="instruction-section">
        {[
          {
            text: "Enter the novel you are interested in the text box",
            icon: FaBookOpen,
            step: 1,
            showNumber: true,
          },
          {
            text: "One click to detect characters and generate a screenplay",
            icon: FaTheaterMasks,
            step: 2,
            showNumber: true,
          },
          {
            text: "One click to assign the best voices to each character",
            icon: FaMicrophone,
            step: 3,
            showNumber: true,
          },
          {
            text: "One click to generate the voices for the novel",
            icon: FaVolumeUp,
            step: 4,
            showNumber: true,
          },
          {
            text: "One click to infer the sound effect description",
            icon: FaDrumSteelpan,
            step: 5,
            showNumber: true,
          },
          {
            text: "One click to generate sound effect audio",
            icon: FaMusic,
            step: 6,
            showNumber: true,
          },
          {
            text: "One click to generate background music description",
            icon: FaCompactDisc,
            step: 7,
            showNumber: true,
          },
          {
            text: "One click to generate background music audio",
            icon: FaGuitar,
            step: 8,
            showNumber: true,
          },
          {
            text: "Enjoy your multicast audiodrama!",
            icon: FaHeadphones,
            step: 9,
            showNumber: false, // Hide number for last instruction
          },
        ].map(({ text, icon: Icon, step, showNumber }) => (
          <div key={step} className="instruction-item">
            {showNumber && <div className="step-number">{step}</div>}
            <Icon className="instruction-icon" />
            <p className="instruction-text">{text}</p>
          </div>
        ))}
      </div>

      <div className="demo-message">
        <span className="take">Take</span>
        <span className="look">a look</span>
        <span className="at">at our</span>
        <span className="highlight">AI-generated audiodrama</span>
        <span className="and">&</span>
        <span className="listen">listen</span>
        <span className="yourself">for yourself!</span>
      </div>

      <div className="demo-subtitle">
        <span className="interested">Interested in</span>
        <span className="customize">customizing your own audiodramas?</span>
        <span className="scroll">scroll up</span>
        <span className="and">&</span>
        <span className="start">start demo!</span>
      </div>

      <div className="toggle-container">
        <div className="toggle-wrapper">
          <button
            className={`toggle-button ${activeTab === "text" ? "active" : ""}`}
            onClick={() => setActiveTab("text")}
          >
            Text Input
          </button>
          <button
            className={`toggle-button ${activeTab === "audio" ? "active" : ""}`}
            onClick={() => setActiveTab("audio")}
          >
            Audiodrama Output
          </button>
        </div>
      </div>

      {activeTab === "text" ? (
        <div className="sync-container">
          <div className="multitrack-text-container">
            <h3 className="multitrack-text-title">✎ Raw Text</h3>
            <div className="multitrack-text-content">{rawText}</div>
          </div>
        </div>
      ) : (
        <div className="sync-container">
          <PanelGroup direction="horizontal">
            <Panel minSize={20} defaultSize={25}>
              <div className={`text-editing`}>
                <h3 className="screenplay-title">📖 Screenplay</h3>
                {renderScreenplayWithClickableSentences()}
              </div>
            </Panel>
            <PanelResizeHandle />
            <Panel minSize={20} defaultSize={25}>
              <div className="multitrack-container">
                <h3 className="multitrack-title">🎼 Multitrack Audio</h3>
                <div className="multitrack-wrapper">
                  <div className="multitrack-controls">
                    <button
                      onClick={handlePlayPauseMultitrack}
                      className="play-pause-button"
                    >
                      {isPlayingMultitrack ? <FaPause /> : <FaPlay />}
                    </button>
                  </div>
                  <div className="track-labels">{renderTrackLabels()}</div>
                  <div
                    ref={multitrackContainerRef}
                    style={{ width: "100%", height: "200px" }}
                  ></div>
                </div>
              </div>
            </Panel>
          </PanelGroup>
        </div>
      )}

      <div className="social-proof">
        <h3 className="social-proof-title">Trusted By Industry Leaders</h3>
        <div className="partner-logos">
          <div className="partner-item">
            <img
              src="/assets/partners/stanford.png"
              alt="Stanford University"
              className="partner-logo stanford"
            />
          </div>
          {/* <div className="partner-item">
            <img
              src="/assets/partners/acx.png"
              alt="ACX Amazon"
              className="partner-logo acx"
            />
          </div> */}
          <div className="partner-item">
            <img
              src="/assets/partners/microsoft.png"
              alt="Microsoft"
              className="partner-logo microsoft"
            />
          </div>
        </div>
      </div>

      <div className="company-footer">
        <div className="footer-left">
          <div className="company-info">
            <img
              src="/audiowizard.png"
              alt="AudioWizard"
              className="company-logo"
            />
          </div>
          <div className="copyright">
            Copyright © AudioWizard 2024. All rights reserved.
          </div>
          <a href="mailto:info@audiowizard.ai" className="company-email">
            info@audiowizard.ai
          </a>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
