import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import "../scss/alphaDemo.scss";
import { FaPlay, FaPause, FaShare, FaThumbsUp } from "react-icons/fa";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Multitrack from "wavesurfer-multitrack";
import { IoPlayCircle, IoPauseCircle } from "react-icons/io5";
import {
  transformTextToScreenplay,
  assignVoicesToCharacters,
  generateMultitrackAudio,
  getVoiceGeneration,
  getWordAlignment,
  getMultitrack,
  getCombinedTrack,
  getSeparateTracksZip,
  getRemainingQuota,
  calculateQuotaUsage,
  subtractQuota,
  generateBGMDescription,
  generateBackgroundMusic,
  getBGMTrack,
  generateSoundEffectsDescription,
  getSoundEffectsDescription,
  saveModifiedSoundEffects,
  generateSoundEffects,
  getSoundEffectsTrack,
  saveModifiedScreenplay,
  getUserProjects,
  shareProject,
  getCommunityProjects,
  addReaction,
  getCommunityProject,
} from "../api/service";
import { API_ENDPOINTS } from "../api/api";

function LandingPage() {
  const navigate = useNavigate();
  const [quota, setQuota] = useState("N/A");
  const [voicePreviews, setVoicePreviews] = useState([]);
  const [voicePreviewsLoaded, setVoicePreviewsLoaded] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  const [searchQuery, setSearchQuery] = useState("");

  // Add new state for alpha dialogues
  const [alphaDialogues, setAlphaDialogues] = useState([]);

  // Add new state for voice assignments
  const [characterVoiceAssignments, setCharacterVoiceAssignments] = useState(
    {},
  );
  const [activeCharacterForVoice, setActiveCharacterForVoice] = useState(null);

  const [modalSearchQuery, setModalSearchQuery] = useState("");

  const [alphaMultitrackRef, setAlphaMultitrackRef] = useState(null);
  const alphaMultitrackContainerRef = useRef(null);
  const [isPlayingAlphaMultitrack, setIsPlayingAlphaMultitrack] =
    useState(false);
  const [alphaMultitrackTracks, setAlphaMultitrackTracks] = useState([]);

  const [alphaAlignmentData, setAlphaAlignmentData] = useState([]);
  const [alphaCurrentTime, setAlphaCurrentTime] = useState(null);
  const alphaTimeIntervalRef = useRef(null);

  const [isGeneratingMultitrack, setIsGeneratingMultitrack] = useState(false);
  const [alphaMultitrackPaths, setAlphaMultitrackPaths] = useState(null);

  // Add state for tracking if BGM and SFX have been generated
  const [hasBGMGenerated, setHasBGMGenerated] = useState(false);
  const [hasSFXGenerated, setHasSFXGenerated] = useState(false);

  // Add these state variables to track progress
  const [furthestView, setFurthestView] = useState("input");
  const [completedSteps, setCompletedSteps] = useState([]);
  const [isTransforming, setIsTransforming] = useState(false);
  const [isAssigningVoices, setIsAssigningVoices] = useState(false);

  // Add these new state variables near the top of the component
  const [userEmail, setUserEmail] = useState(null);
  const [sessionTimestamp, setSessionTimestamp] = useState(null);

  // Add this state for quota error
  const [quotaError, setQuotaError] = useState(null);

  // Add this state to track when we need to refresh the view
  const [refreshKey, setRefreshKey] = useState(0);

  // Add these new state variables
  const [currentUserEmail, setCurrentUserEmail] = useState(null); // Current user's email
  const [currentSessionTimestamp, setCurrentSessionTimestamp] = useState(null); // Current user's session
  const [viewingSharedProject, setViewingSharedProject] = useState(null); // Track shared project info

  // Email validation function
  const isValidEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const alertShown = useRef(false);

  // Update the useEffect for email initialization
  useEffect(() => {
    const email = localStorage.getItem("demoEmail");
    if (email && isValidEmail(email)) {
      setCurrentUserEmail(email);
      setUserEmail(email); // Keep this for backward compatibility
    } else {
      console.log("Email is invalid or missing");
      if (!alertShown.current) {
        alertShown.current = true;
        alert(
          "Please start the demo from https://audiowizard.ai/ with a valid email",
        );
        navigate("/");
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchQuota = async () => {
      const storedEmail = localStorage.getItem("demoEmail");
      if (storedEmail) {
        try {
          const response = await getRemainingQuota(storedEmail);
          console.log("Fetching quota response:", response);
          if (
            response.success &&
            response.quota !== undefined &&
            response.quota !== -1
          ) {
            setQuota(response.quota);
          } else {
            setQuota("N/A");
          }
        } catch (error) {
          console.error("Error fetching quota:", error);
          setQuota("N/A");
        }
      }
    };

    fetchQuota();
  }, []);

  // This is a great testing method
  // window.testGetSFX = async () => {
  //   try {
  //     const sfxData = await getSoundEffectsDescription(userEmail, sessionTimestamp);
  //     console.log("Test SFX Data:", sfxData);
  //     setSoundEffectsData(sfxData);
  //   } catch (error) {
  //     console.error("Error fetching SFX:", error);
  //   }
  // };

  // Modify handleTransformTextToScreenplay to set timestamp
  const handleTransformTextToScreenplay = async (inputText) => {
    if (!inputText.trim()) {
      alert("Please enter some text first");
      return;
    }

    if (!userEmail) {
      alert("Please start the demo from the landing page");
      navigate("/");
      return;
    }

    setIsTransforming(true);
    try {
      // Set UTC timestamp when starting the transformation
      const timestamp = new Date().toISOString();
      setCurrentSessionTimestamp(timestamp); // Add this line
      setSessionTimestamp(timestamp);

      const data = await transformTextToScreenplay(
        inputText,
        userEmail,
        timestamp,
      );
      setAlphaDialogues(data.screenplay);
      setActiveView("screenplay");
      setCompletedSteps([...completedSteps, "input"]);
      setFurthestView("screenplay");
    } catch (error) {
      console.error("Error transforming text:", error);
      alert("Failed to transform text. Please try again.");
    } finally {
      setIsTransforming(false);
    }
  };
  const filteredVoices = useMemo(() => {
    return voicePreviews.filter(
      (voice) =>
        voice.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        Object.values(voice.labels || {}).some((label) =>
          label.toLowerCase().includes(searchQuery.toLowerCase()),
        ),
    );
  }, [voicePreviews, searchQuery]);

  const filteredModalVoices = useMemo(() => {
    const filtered = voicePreviews.filter(
      (voice) =>
        voice.name.toLowerCase().includes(modalSearchQuery.toLowerCase()) ||
        Object.values(voice.labels || {}).some((label) =>
          label.toLowerCase().includes(modalSearchQuery.toLowerCase()),
        ),
    );

    console.log("Filtered Results:", filtered);
    return filtered;
  }, [voicePreviews, modalSearchQuery]);

  const handleBackLanding = () => {
    navigate("/");
  };

  const alphaTextContainerRef = useRef(null); // Add this for alpha section

  const getHashFromString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32-bit integer
    }
    return Math.abs(hash);
  };

  const getColorFromSeed = (seed) => {
    const hash = getHashFromString(seed);
    const hue = hash % 360; // Use modulo to get a value between 0-360
    const saturation = 65; // Fixed saturation
    const lightness = 65; // Fixed lightness
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  // Add effect to preload voice data and audio files
  useEffect(() => {
    const preloadVoices = async () => {
      try {
        // First load the voice info JSON
        const response = await fetch("/assets/voice_previews/voice_info.json");
        const data = await response.json();

        // Create audio elements and preload all preview files
        const voicesWithPreload = await Promise.all(
          data.map(async (voice) => {
            // Update filename format to include voice ID
            const filename = `${voice.name}-${voice.voice_id}.mp3`;
            const audioPath = `/assets/voice_previews/audio/${filename}`;

            // Create and preload audio
            const audio = new Audio();
            audio.src = audioPath;

            // Wait for audio to be loaded
            try {
              await new Promise((resolve, reject) => {
                audio.addEventListener("loadeddata", resolve);
                audio.addEventListener("error", reject);
                // Start loading the audio
                audio.load();
              });
              console.log(`Loaded audio for ${voice.name}`);
            } catch (error) {
              console.error(`Failed to load audio for ${voice.name}:`, error);
            }

            return {
              ...voice,
              filename,
              audioPath,
              color: getColorFromSeed(voice.name),
            };
          }),
        );

        setVoicePreviews(voicesWithPreload);
        setVoicePreviewsLoaded(true);
        console.log("All voice previews loaded");
      } catch (error) {
        console.error("Error loading voice previews:", error);
        setVoicePreviewsLoaded(false);
      }
    };

    preloadVoices();
  }, []);

  // Handle play/pause for voice preview
  const handlePlayVoice = (voice) => {
    if (audioRef.current) {
      if (selectedVoice?.filename === voice.filename && isPlaying) {
        // If clicking the same voice that's playing, pause it
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        // If clicking a different voice or a paused voice, play it
        if (selectedVoice?.filename !== voice.filename) {
          audioRef.current.src = `/assets/voice_previews/audio/${voice.filename}`;
        }
        audioRef.current.play();
        setSelectedVoice(voice);
        setIsPlaying(true);
      }
    }
  };

  // Handle audio ending
  const handleAudioEnded = () => {
    setIsPlaying(false);
  };

  // Add state for active view and text input
  const [activeView, setActiveView] = useState("input");
  const [inputText, setInputText] = useState("");
  const [textError, setTextError] = useState("");
  const WORD_LIMIT = 2000;

  // Add function to handle text change
  const handleTextChange = (e) => {
    const text = e.target.value;
    const wordCount = text.trim().split(/\s+/).length;

    if (wordCount > WORD_LIMIT) {
      setTextError(`Text exceeds ${WORD_LIMIT} words limit`);
    } else {
      setTextError("");
      setInputText(text);
    }
  };

  // Add this after the handleTextChange function
  const handleMusicDescriptionChange = (e) => {
    setMusicDescription(e.target.value);
  };

  // Modify handleVoiceAssignment to use email and timestamp
  const handleVoiceAssignment = async () => {
    if (!sessionTimestamp || !userEmail) {
      alert("Invalid session. Please restart the demo.");
      return;
    }

    setIsAssigningVoices(true);
    try {
      // First save the modified screenplay
      const saveSuccess = await saveModifiedScreenplay(
        alphaDialogues,
        userEmail,
        sessionTimestamp,
      );

      if (!saveSuccess) {
        throw new Error("Failed to save modified screenplay");
      }

      // Then proceed with voice assignment
      const data = await assignVoicesToCharacters(userEmail, sessionTimestamp);
      const voiceAssignments = {};
      Object.entries(data.voice_assignments).forEach(([character, voiceId]) => {
        const matchingVoice = voicePreviews.find(
          (voice) => voice.voice_id === voiceId,
        );
        if (matchingVoice) {
          voiceAssignments[character] = matchingVoice;
        }
      });
      setCharacterVoiceAssignments(voiceAssignments);
      setActiveView("voice-assignment");
      setCompletedSteps([...completedSteps, "screenplay"]);
      setFurthestView("voice-assignment");
    } catch (error) {
      console.error("Error assigning voices:", error);
      alert("Failed to assign voices. Please try again.");
    } finally {
      setIsAssigningVoices(false);
    }
  };

  // Add this function to handle play/pause for alpha multitrack
  const handlePlayPauseAlphaMultitrack = useCallback(() => {
    if (alphaMultitrackRef) {
      if (alphaMultitrackRef.isPlaying()) {
        alphaMultitrackRef.pause();
        setIsPlayingAlphaMultitrack(false);
      } else {
        alphaMultitrackRef.play();
        setIsPlayingAlphaMultitrack(true);
      }
    }
  }, [alphaMultitrackRef]);

  // Add this function near your other utility functions
  const getAlphaTrackColor = (character) => {
    const colors = [
      { wave: "#8B5CF6", progress: "#6D28D9" }, // Violet
      { wave: "#F59E0B", progress: "#D97706" }, // Amber
      { wave: "#EC4899", progress: "#DB2777" }, // Pink
      { wave: "#10B981", progress: "#059669" }, // Emerald
      { wave: "#3B82F6", progress: "#2563EB" }, // Blue
      { wave: "#EF4444", progress: "#DC2626" }, // Red
      { wave: "#6366F1", progress: "#4F46E5" }, // Indigo
      { wave: "#14B8A6", progress: "#0D9488" }, // Teal
      { wave: "#F472B6", progress: "#DB2777" }, // Pink
      { wave: "#84CC16", progress: "#65A30D" }, // Lime
    ];

    // Create a deterministic but random index based on character name
    const hash = character.split("").reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);

    const index = Math.abs(hash) % colors.length;
    return colors[index];
  };

  // Add this function to handle word click in alpha multitrack
  const handleAlphaWordClick = useCallback(
    (startTime) => {
      if (alphaMultitrackRef) {
        alphaMultitrackRef.setTime(startTime);
      }
    },
    [alphaMultitrackRef],
  );

  // Update the isAlphaWordPlaying function to use absolute times
  const isAlphaWordPlaying = useCallback(
    (wordStartTime, wordEndTime) => {
      return (
        alphaCurrentTime !== null &&
        alphaCurrentTime >= wordStartTime &&
        alphaCurrentTime <= wordEndTime
      );
    },
    [alphaCurrentTime],
  );

  // Update the renderAlphaMultitrackScreenplay function to use absolute times
  const renderAlphaMultitrackScreenplay = useCallback(() => {
    if (!alphaDialogues.length) return null;

    console.log(
      `(renderAlphaMultitrackScreenplay) debug 3 ${JSON.stringify(alphaDialogues)}`,
    );
    console.log(
      `(renderAlphaMultitrackScreenplay) debug 4 ${JSON.stringify(alphaAlignmentData)}`,
    );
    return (
      <div className="alpha-screenplay-content">
        {alphaDialogues.map((dialogue, dialogueIndex) => {
          const [character, text] = Object.entries(dialogue)[0];
          const alignment = alphaAlignmentData[dialogueIndex];

          let content;
          // console.log(`(renderAlphaMultitrackScreenplay) debug 4 ${JSON.stringify(alignment)}`)
          if (alignment) {
            content = alignment.map((word, wordIndex) => {
              const isCurrentlyPlaying = isAlphaWordPlaying(
                word.absoluteStartTime,
                word.absoluteEndTime,
              );

              return (
                <span
                  key={`${dialogueIndex}-${wordIndex}`}
                  className={`alpha-word ${isCurrentlyPlaying ? "current-word" : ""}`}
                  onClick={() => handleAlphaWordClick(word.absoluteStartTime)}
                >
                  {word.word}
                </span>
              );
            });
            // console.log(`(renderAlphaMultitrackScreenplay) debug 5 ${JSON.stringify(content)}`)
          } else {
            content = <span>{text}</span>;
          }

          return (
            <div key={dialogueIndex} className="dialogue-item">
              <span className="character">
                {character.endsWith(":") ? character : `${character}:`}
              </span>
              {content}
            </div>
          );
        })}
      </div>
    );
  }, [
    alphaDialogues,
    alphaAlignmentData,
    isAlphaWordPlaying,
    handleAlphaWordClick,
  ]);

  // Add this helper function to get audio duration
  const getAudioDuration = async (url) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio();
      audio.addEventListener("loadedmetadata", () => {
        resolve(audio.duration);
      });
      audio.addEventListener("error", reject);
      audio.src = url;
    });
  };

  // First, let's extract the word alignment loading logic into a separate function
  const loadWordAlignments = async (
    email,
    timestamp,
    dialogues,
    isShared = false,
  ) => {
    try {
      const alignments = [];
      let cumulativeTime = 0;

      for (let i = 0; i < dialogues.length; i++) {
        if (i > 0) {
          const audioBlob = await getVoiceGeneration(
            email,
            timestamp,
            i - 1,
            isShared,
          );
          const audioUrl = URL.createObjectURL(audioBlob);
          const duration = await getAudioDuration(audioUrl);
          URL.revokeObjectURL(audioUrl);
          cumulativeTime += duration;
        }

        const data = await getWordAlignment(email, timestamp, i, isShared);

        const processedData = data.map((word) => ({
          ...word,
          absoluteStartTime: word.start_time + cumulativeTime,
          absoluteEndTime: word.end_time + cumulativeTime,
        }));

        alignments.push(processedData);
      }

      return alignments;
    } catch (error) {
      console.error("Error loading word alignments:", error);
      throw error;
    }
  };

  // Then update the useEffect for loading alignments
  useEffect(() => {
    const loadAlphaAlignments = async () => {
      // Don't load alignments if we're viewing a shared project
      // since handleCommunityProjectSelect handles that
      if (viewingSharedProject) return;

      if (!alphaMultitrackPaths || !userEmail || !sessionTimestamp) return;

      try {
        const alignments = await loadWordAlignments(
          userEmail,
          sessionTimestamp,
          alphaDialogues,
        );
        setAlphaAlignmentData(alignments);
      } catch (error) {
        console.error("Error loading alpha alignments:", error);
      }
    };

    if (alphaDialogues.length > 0 && alphaMultitrackPaths) {
      loadAlphaAlignments();
    }
  }, [
    alphaDialogues,
    alphaMultitrackPaths,
    userEmail,
    sessionTimestamp,
    viewingSharedProject,
  ]);

  // Add effect to track current time in alpha multitrack
  useEffect(() => {
    if (alphaMultitrackRef) {
      if (isPlayingAlphaMultitrack) {
        alphaTimeIntervalRef.current = setInterval(() => {
          const currentTime = alphaMultitrackRef.getCurrentTime();
          setAlphaCurrentTime(currentTime);
        }, 100);
      } else {
        if (alphaTimeIntervalRef.current) {
          clearInterval(alphaTimeIntervalRef.current);
        }
      }

      return () => {
        if (alphaTimeIntervalRef.current) {
          clearInterval(alphaTimeIntervalRef.current);
        }
      };
    }
  }, [isPlayingAlphaMultitrack, alphaMultitrackRef]);

  const handleGenerateVoice = async () => {
    if (!sessionTimestamp || !userEmail) {
      alert("Invalid session. Please restart the demo.");
      return;
    }

    try {
      setIsGeneratingMultitrack(true);
      setQuotaError(null);

      // Step 1: Get current quota
      const quotaResponse = await getRemainingQuota(userEmail);
      const currentQuota = quotaResponse.quota;

      // Step 2: Calculate needed quota
      console.log(`debug alphaDialogues: ${JSON.stringify(alphaDialogues)}`);
      const neededQuota = await calculateQuotaUsage(alphaDialogues);

      // Step 3: Check if we have enough quota
      if (neededQuota > currentQuota) {
        setQuotaError(
          `Insufficient quota. You need ${neededQuota} characters but only have ${currentQuota} remaining. Please contact support to increase your quota.`,
        );
        return;
      }

      // Step 4: Subtract quota
      const subtractSuccess = await subtractQuota(userEmail, neededQuota);
      if (!subtractSuccess) {
        setQuotaError("Failed to update quota. Please try again.");
        return;
      }

      // Update displayed quota
      setQuota(currentQuota - neededQuota);

      // Continue with voice generation
      const voiceAssignments = {};
      Object.entries(characterVoiceAssignments).forEach(
        ([character, voice]) => {
          voiceAssignments[character] = voice.voice_id;
        },
      );

      const data = await generateMultitrackAudio(
        alphaDialogues,
        voiceAssignments,
        userEmail,
        sessionTimestamp,
      );

      setAlphaMultitrackPaths(data);
      setActiveView("multitrack-audio");
      setCompletedSteps([...completedSteps, "voice-assignment"]);
      setFurthestView("voice-assignment");
    } catch (error) {
      console.error("Error generating multitrack audio:", error);
      setQuotaError("Failed to generate audio. Please try again.");
    } finally {
      setIsGeneratingMultitrack(false);
    }
  };

  // Update isViewAvailable function
  const isViewAvailable = (view) => {
    // Input view is always available
    if (view === "input") return true;

    // If viewing a shared project, use special logic
    if (viewingSharedProject) {
      switch (view) {
        case "screenplay":
          return alphaDialogues && alphaDialogues.length > 0;
        case "voice-assignment":
          return Object.keys(characterVoiceAssignments).length > 0;
        case "music-description":
          return musicDescription !== "";
        case "sound-effects":
          return soundEffectsData.length > 0;
        case "multitrack-audio":
          return alphaMultitrackPaths !== null;
        case "gallery":
        case "projects":
        case "community":
          return true; // These views are always available
        default:
          return false;
      }
    }

    // For normal workflow, enforce sequential progression
    const baseViewOrder = [
      "input",
      "screenplay",
      "voice-assignment",
      "music-description",
      "sound-effects",
      "multitrack-audio"
    ];

    // Gallery, projects, and community are always available
    if (["gallery", "projects", "community"].includes(view)) {
      return true;
    }

    // For main workflow views, check progression
    if (baseViewOrder.includes(view)) {
      // Get the main view (before any comma)
      const mainView = furthestView.split(",")[0];
      const currentIndex = baseViewOrder.indexOf(mainView);
      const viewIndex = baseViewOrder.indexOf(view);

      // Special handling for music-description and sound-effects
      if (view === "music-description" || view === "sound-effects") {
        // These views are only available after voice-assignment is completed
        // and only if they're part of furthestView
        return currentIndex >= baseViewOrder.indexOf("voice-assignment") &&
               furthestView.includes(view);
      }

      // For multitrack-audio, require voice-assignment completion
      if (view === "multitrack-audio") {
        return currentIndex >= baseViewOrder.indexOf("voice-assignment") &&
               alphaMultitrackPaths !== null;
      }

      // For other views, use normal progression
      return viewIndex <= currentIndex;
    }

    return false;
  };

  // Add state for dropdown
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Add this helper function to handle downloads
  const downloadBlob = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  // Update the download handlers
  const handleCombinedDownload = async () => {
    try {
      const blob = await getCombinedTrack(userEmail, sessionTimestamp);
      downloadBlob(blob, "combined_audio.mp3");
    } catch (error) {
      console.error("Error downloading combined track:", error);
      alert("Failed to download combined track. Please try again.");
    }
  };

  const handleSeparateDownload = async () => {
    try {
      const blob = await getSeparateTracksZip(userEmail, sessionTimestamp);
      downloadBlob(blob, "separate_tracks.zip");
    } catch (error) {
      console.error("Error downloading separate tracks:", error);
      alert("Failed to download separate tracks. Please try again.");
    }
  };

  // Add this after the activeView state declaration
  const [musicDescription, setMusicDescription] = useState("");

  // Add this to your state declarations
  const [isGeneratingBGM, setIsGeneratingBGM] = useState(false);

  // Update the handleGenerateBGMDescription function
  const handleGenerateBGMDescription = async () => {
    if (!sessionTimestamp || !userEmail) {
      alert("Invalid session. Please restart the demo.");
      return;
    }

    try {
      setIsGeneratingBGM(true);
      const description = await generateBGMDescription(
        userEmail,
        sessionTimestamp,
      );
      setMusicDescription(description);
      // Only add music-description to furthestView
      setFurthestView(
        furthestView.includes("music-description")
          ? furthestView
          : furthestView + ",music-description",
      );
      setActiveView("music-description");
    } catch (error) {
      console.error("Error generating BGM description:", error);
      alert("Failed to generate BGM description. Please try again.");
    } finally {
      setIsGeneratingBGM(false);
    }
  };

  // Add new state for BGM generation
  const [isGeneratingMusic, setIsGeneratingMusic] = useState(false);

  // Keep only one initialization function
  const initializeMultitrack = useCallback(async () => {
    if (
      !alphaMultitrackContainerRef.current ||
      !alphaMultitrackPaths ||
      !alphaDialogues.length
    ) {
      console.log("Missing required data for multitrack initialization");
      return;
    }

    console.log("Initializing multitrack with dialogues:", alphaDialogues);

    // Destroy existing multitrack if it exists
    if (alphaMultitrackRef) {
      alphaMultitrackRef.destroy();
    }

    const defaultHeight = 55;
    const characters = [
      ...new Set(
        alphaDialogues.map((dialogue) =>
          Object.keys(dialogue)[0].replace(":", ""),
        ),
      ),
    ];

    const tracks = [];
    const isShared = viewingSharedProject !== null;

    // Use shared project info if available, otherwise use current user's info
    const projectEmail = viewingSharedProject
      ? viewingSharedProject.email
      : currentUserEmail;
    const projectTimestamp = viewingSharedProject
      ? viewingSharedProject.timestamp
      : currentSessionTimestamp;

    // Add character tracks
    for (const character of characters) {
      try {
        const colors = getAlphaTrackColor(character);
        console.log(`Fetching track for character: ${character}`); // Debug log

        const audioBlob = await getMultitrack(
          projectEmail,
          projectTimestamp,
          character,
          isShared,
        );

        const audioUrl = URL.createObjectURL(audioBlob);
        tracks.push({
          id: character,
          url: audioUrl,
          options: {
            waveColor: colors.wave,
            progressColor: colors.progress,
            height: defaultHeight,
          },
        });
      } catch (error) {
        console.error(
          `Failed to load track for character: ${character}`,
          error,
        );
        // Continue with other tracks even if one fails
        continue;
      }
    }

    // Try to add BGM track if it has been generated
    console.log(`(debug 1) hasBGMGenerated: ${hasBGMGenerated}`);
    if (hasBGMGenerated) {
      try {
        const bgmBlob = await getBGMTrack(
          projectEmail, // Use project email
          projectTimestamp, // Use project timestamp
          isShared,
        );
        console.log("BGM track found and loaded");
        const bgmUrl = URL.createObjectURL(bgmBlob);
        tracks.push({
          id: "bgm",
          url: bgmUrl,
          options: {
            waveColor: "#8B5CF6",
            progressColor: "#6D28D9",
            height: defaultHeight,
          },
        });
      } catch (error) {
        console.log("No BGM track available yet");
      }
    }

    // Try to add SFX track if it has been generated
    if (hasSFXGenerated) {
      try {
        const sfxBlob = await getSoundEffectsTrack(
          projectEmail, // Use project email
          projectTimestamp, // Use project timestamp
          isShared,
        );
        const sfxUrl = URL.createObjectURL(sfxBlob);
        tracks.push({
          id: "Sound Effects",
          url: sfxUrl,
          options: {
            waveColor: "#10B981",
            progressColor: "#059669",
            height: defaultHeight,
          },
        });
      } catch (error) {
        console.log("No sound effects track available yet");
      }
    }

    setAlphaMultitrackTracks(tracks);

    const multitrack = Multitrack.create(tracks, {
      container: alphaMultitrackContainerRef.current,
      minPxPerSec: 50,
      cursorWidth: 2,
      cursorColor: "#D72F21",
      trackBackground: "#f0f0f0",
      trackBorderColor: "#ddd",
    });

    setAlphaMultitrackRef(multitrack);

    multitrack.on("play", () => setIsPlayingAlphaMultitrack(true));
    multitrack.on("pause", () => setIsPlayingAlphaMultitrack(false));
    multitrack.on("finish", () => setIsPlayingAlphaMultitrack(false));
  }, [
    alphaDialogues,
    alphaMultitrackRef,
    currentSessionTimestamp,
    currentUserEmail,
    alphaMultitrackPaths,
    hasBGMGenerated,
    hasSFXGenerated,
    viewingSharedProject,
  ]);

  // Initialize in these cases:
  // 1. First time entering multitrack audio section
  // 2. When returning to multitrack audio section from another view
  // 3. After generating background music (but only through handleGenerateMusic)
  useEffect(() => {
    // Initialize multitrack
    if (
      activeView === "multitrack-audio" &&
      alphaMultitrackPaths &&
      !alphaMultitrackRef &&
      !isGeneratingMusic &&
      alphaDialogues.length > 0
    ) {
      console.log("Initializing multitrack...");
      initializeMultitrack();
    }

    // Cleanup when leaving multitrack view
    return () => {
      if (activeView !== "multitrack-audio" && alphaMultitrackRef) {
        console.log("Cleaning up multitrack...");
        alphaMultitrackRef.destroy();
        setAlphaMultitrackRef(null);
        setAlphaMultitrackTracks([]);
      }
    };
  }, [
    activeView,
    alphaMultitrackRef,
    alphaMultitrackPaths,
    isGeneratingMusic,
    initializeMultitrack,
    alphaDialogues,
  ]);

  // Also update the cleanup in the useEffect to destroy the multitrack when leaving the view
  useEffect(() => {
    if (activeView !== "multitrack-audio" && alphaMultitrackRef) {
      alphaMultitrackRef.destroy();
      setAlphaMultitrackRef(null);
    }
  }, [activeView, alphaMultitrackRef]);

  // Update handleGenerateMusic function
  const handleGenerateMusic = async () => {
    if (!sessionTimestamp || !userEmail) {
      alert("Invalid session. Please restart the demo.");
      return;
    }

    try {
      setIsGeneratingMusic(true);
      setQuotaError(null);

      // Step 1: Get current quota
      const quotaResponse = await getRemainingQuota(userEmail);
      const currentQuota = quotaResponse.quota;

      // Step 2: Calculate needed quota - use the same logic as voice generation
      const neededQuota = musicDescription.length;

      // Step 3: Check if we have enough quota
      if (neededQuota > currentQuota) {
        setQuotaError(
          `Insufficient quota. You need ${neededQuota} characters but only have ${currentQuota} remaining. Please contact support to increase your quota.`,
        );
        return;
      }

      // Step 4: Subtract quota
      const subtractSuccess = await subtractQuota(userEmail, neededQuota);
      if (!subtractSuccess) {
        setQuotaError("Failed to update quota. Please try again.");
        return;
      }

      // Update displayed quota
      setQuota(currentQuota - neededQuota);

      console.log(`(debug 3) currentQuota: ${currentQuota}`);
      // Continue with BGM generation
      await generateBackgroundMusic(
        musicDescription,
        userEmail,
        sessionTimestamp,
      );
      setHasBGMGenerated(true);

      // Clean up existing multitrack
      if (alphaMultitrackRef) {
        alphaMultitrackRef.destroy();
        setAlphaMultitrackRef(null);
      }

      // Initialize with new tracks including BGM
      await initializeMultitrack();
      setActiveView("multitrack-audio");
    } catch (error) {
      console.error("Error generating background music:", error);
      alert("Failed to generate background music. Please try again.");
    } finally {
      setIsGeneratingMusic(false);
    }
  };

  // Add this after the musicDescription state
  const [isGeneratingSFX, setIsGeneratingSFX] = useState(false);
  const [soundEffectsData, setSoundEffectsData] = useState([]);
  const [selectedSoundEffect, setSelectedSoundEffect] = useState(null);

  // Add ref for the right panel
  const sfxContentRef = useRef(null);

  // Rename the original function but keep its implementation exactly the same
  const handleGenerateSFXDescription = async () => {
    if (!sessionTimestamp || !userEmail) {
      alert("Invalid session. Please restart the demo.");
      return;
    }

    try {
      setIsGeneratingSFX(true);
      await generateSoundEffectsDescription(userEmail, sessionTimestamp);

      // Load all sound effects descriptions at once
      const sfxData = await getSoundEffectsDescription(
        userEmail,
        sessionTimestamp,
      );
      console.log(`(debug) sfxData: ${JSON.stringify(sfxData)}`);
      setSoundEffectsData(sfxData);

      // Only add sound-effects to furthestView
      setFurthestView(
        furthestView.includes("sound-effects")
          ? furthestView
          : furthestView + ",sound-effects",
      );
      setActiveView("sound-effects");
    } catch (error) {
      console.error("Error generating sound effects:", error);
      alert("Failed to generate sound effects. Please try again.");
    } finally {
      setIsGeneratingSFX(false);
    }
  };

  // Update handleGenerateSFXAudio function
  const handleGenerateSFXAudio = async () => {
    if (!sessionTimestamp || !userEmail) {
      alert("Invalid session. Please restart the demo.");
      return;
    }

    try {
      setIsGeneratingSFX(true);
      setQuotaError(null);

      // Step 1: Get current quota
      const quotaResponse = await getRemainingQuota(userEmail);
      const currentQuota = quotaResponse.quota;

      // Step 2: Calculate needed quota - sum up all SFX descriptions
      const neededQuota = soundEffectsData.reduce(
        (total, sfx) => total + sfx.foley_description.length,
        0,
      );

      // Step 3: Check if we have enough quota
      if (neededQuota > currentQuota) {
        setQuotaError(
          `Insufficient quota. You need ${neededQuota} characters but only have ${currentQuota} remaining. Please contact support to increase your quota.`,
        );
        return;
      }

      // Step 4: Subtract quota
      const subtractSuccess = await subtractQuota(userEmail, neededQuota);
      if (!subtractSuccess) {
        setQuotaError("Failed to update quota. Please try again.");
        return;
      }

      // Update displayed quota
      setQuota(currentQuota - neededQuota);

      // Save the current modified sound effects
      await saveModifiedSoundEffects(
        userEmail,
        sessionTimestamp,
        soundEffectsData,
      );

      // Generate sound effects
      const success = await generateSoundEffects(userEmail, sessionTimestamp);

      if (!success) {
        throw new Error("Failed to generate sound effects");
      }

      // Set SFX as generated
      setHasSFXGenerated(true);

      // Clean up existing multitrack
      if (alphaMultitrackRef) {
        alphaMultitrackRef.destroy();
        setAlphaMultitrackRef(null);
      }

      // Initialize with new tracks including SFX
      await initializeMultitrack();

      // Switch to multitrack audio view
      setActiveView("multitrack-audio");
    } catch (error) {
      console.error("Error in sound effects generation:", error);
      alert("Failed to generate sound effects. Please try again.");
    } finally {
      setIsGeneratingSFX(false);
    }
  };

  // Update the click handler
  const handleSfxIconClick = (dialogueIndex, sfx) => {
    setSelectedSoundEffect({
      dialogueIndex,
      sfx,
    });

    // Find the target element
    const targetElement = document.getElementById(
      `sfx-${dialogueIndex}-${sfx.start_word_idx}-${sfx.end_word_idx}`,
    );

    if (targetElement && sfxContentRef.current) {
      // Calculate scroll position within the container
      const container = sfxContentRef.current;
      const targetTop = targetElement.offsetTop;
      const containerHeight = container.clientHeight;

      // Smooth scroll the container
      container.scrollTo({
        top: targetTop - containerHeight / 2 + targetElement.clientHeight / 2,
        behavior: "smooth",
      });
    }
  };

  // Add this helper function to get word positions
  const getWordPositions = (text) => {
    const words = [];
    const regex = /\S+/g;
    let match;
    while ((match = regex.exec(text)) !== null) {
      words.push({
        word: match[0],
        start: match.index,
        end: match.index + match[0].length,
      });
    }
    return words;
  };

  // Add these new state variables near the top with other states
  const [selectedText, setSelectedText] = useState(null);
  const [showSfxPopup, setShowSfxPopup] = useState(false);
  const [sfxPopupPosition, setSfxPopupPosition] = useState({ x: 0, y: 0 });
  const [showSfxDescriptionModal, setShowSfxDescriptionModal] = useState(false);
  const [newSfxDescription, setNewSfxDescription] = useState("");

  // Add this function after other handlers
  const handleTextSelection = (e, dialogueIndex) => {
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();

    if (selectedText) {
      const range = selection.getRangeAt(0);
      const dialogueElement = e.currentTarget;
      const words = Array.from(dialogueElement.querySelectorAll(".alpha-word"));

      // Find the containing word elements for start and end of selection
      const startContainer = range.startContainer.parentElement;
      const endContainer = range.endContainer.parentElement;

      let startWordIdx = -1;
      let endWordIdx = -1;

      // Find the full word indices, not just the selection points
      words.forEach((word, idx) => {
        if (word === startContainer || word.contains(startContainer)) {
          startWordIdx = idx;
        }
        if (word === endContainer || word.contains(endContainer)) {
          endWordIdx = idx;
        }
      });

      if (startWordIdx !== -1 && endWordIdx !== -1) {
        // Clear the original selection
        selection.removeAllRanges();

        // Create a new range for the full words
        const newRange = document.createRange();
        newRange.setStartBefore(words[startWordIdx].firstChild);
        newRange.setEndAfter(words[endWordIdx].lastChild);
        selection.addRange(newRange);

        const dialogue = alphaDialogues[dialogueIndex];
        const [character, fullText] = Object.entries(dialogue)[0];

        // Get the words array using getWordPositions
        const wordsInfo = getWordPositions(fullText);

        // Extract the exact selected text from the words array
        const selectedWords = wordsInfo
          .slice(startWordIdx, endWordIdx + 1)
          .map((w) => w.word)
          .join(" ");

        // Get the last selected word's position
        const lastWord = words[endWordIdx];
        const rect = lastWord.getBoundingClientRect();

        setSelectedText({
          text: selectedWords,
          dialogueIndex,
          startWordIdx,
          endWordIdx,
          sentence: fullText,
        });

        // Position popup right next to the last word
        setSfxPopupPosition({
          x: rect.right + 5,
          y: rect.top + rect.height / 2,
        });
        setShowSfxPopup(true);
      }
    } else {
      setShowSfxPopup(false);
    }
  };

  // Add this function to handle adding new SFX
  const handleAddNewSfx = () => {
    setShowSfxPopup(false);
    setShowSfxDescriptionModal(true);
  };

  // Add this function to handle confirming new SFX
  const handleConfirmNewSfx = () => {
    if (newSfxDescription.trim() && selectedText) {
      const newSfx = {
        dialogue_index: selectedText.dialogueIndex,
        start_word_idx: selectedText.startWordIdx,
        end_word_idx: selectedText.endWordIdx,
        foley_description: newSfxDescription,
        sentence: selectedText.text,
      };
      console.log(`(debug) newSfx: ${JSON.stringify(newSfx)}`);

      setSoundEffectsData([...soundEffectsData, newSfx]);
      setShowSfxDescriptionModal(false);
      setNewSfxDescription("");
      setSelectedText(null);
    }
  };

  // Update the renderSoundEffectsView function to include the new modals
  const renderSoundEffectsView = () => {
    return (
      <div className="alpha-sfx-view">
        <PanelGroup direction="horizontal">
          <Panel minSize={20} defaultSize={50}>
            <div className="alpha-sfx-screenplay-panel">
              <div className="alpha-sfx-screenplay-header">
                <h3 className="alpha-sfx-screenplay-title">📖 Screenplay</h3>
                <h4 className="alpha-sfx-screenplay-subtitle">
                  🖱️ Select Text to Add Sound Effects
                </h4>
              </div>
              <div className="alpha-sfx-screenplay-content">
                {alphaDialogues.map((dialogue, dialogueIndex) => {
                  const [character, text] = Object.entries(dialogue)[0];
                  const words = getWordPositions(text);

                  return (
                    <div
                      key={dialogueIndex}
                      className="dialogue-item"
                      onMouseUp={(e) =>
                        !isReadOnly && handleTextSelection(e, dialogueIndex)
                      }
                      title={
                        !isReadOnly ? "🖱️ Select text to add sound effects" : ""
                      }
                    >
                      <span className="character">
                        {character.endsWith(":") ? character : `${character}:`}
                      </span>
                      {words.map((wordInfo, wordIndex) => {
                        const sfxForWord = soundEffectsData.find(
                          (sfx) =>
                            sfx.dialogue_index === dialogueIndex &&
                            wordIndex >= sfx.start_word_idx &&
                            wordIndex <= sfx.end_word_idx,
                        );

                        const isEndOfSoundEffect = soundEffectsData.some(
                          (sfx) =>
                            sfx.dialogue_index === dialogueIndex &&
                            wordIndex === sfx.end_word_idx,
                        );

                        const soundEffectAtEnd = isEndOfSoundEffect
                          ? soundEffectsData.find(
                              (sfx) =>
                                sfx.dialogue_index === dialogueIndex &&
                                sfx.end_word_idx === wordIndex &&
                                sfx.start_word_idx ===
                                  Math.min(
                                    ...soundEffectsData
                                      .filter(
                                        (s) =>
                                          s.dialogue_index === dialogueIndex &&
                                          s.end_word_idx === wordIndex,
                                      )
                                      .map((s) => s.start_word_idx),
                                  ),
                            )
                          : null;

                        return (
                          <span
                            key={wordIndex}
                            className={`alpha-word ${sfxForWord ? "has-sfx" : ""}`}
                          >
                            {wordInfo.word}
                            {soundEffectAtEnd && (
                              <span
                                className="sfx-icon"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleSfxIconClick(
                                    dialogueIndex,
                                    soundEffectAtEnd,
                                  );
                                }}
                              >
                                🎵
                              </span>
                            )}
                          </span>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </Panel>
          <PanelResizeHandle />
          <Panel minSize={20} defaultSize={50}>
            <div className="alpha-sfx-container">
              <div className="alpha-sfx-title-section">
                <h3 className="alpha-sfx-title">🔊 Sound Effects</h3>
                <button
                  className={`generate-sfx-button ${isGeneratingSFX ? "loading" : ""}`}
                  onClick={handleGenerateSFXAudio}
                  disabled={isReadOnly || isGeneratingSFX}
                >
                  {isGeneratingSFX ? (
                    <>
                      <div className="loading-spinner" />
                      <span className="button-text">Generating...</span>
                    </>
                  ) : (
                    <>
                      <span className="button-icon">🔊</span>
                      <span className="button-text">Generate SFX</span>
                      <span className="button-arrow">→</span>
                    </>
                  )}
                </button>
              </div>
              <div className="alpha-sfx-content" ref={sfxContentRef}>
                {soundEffectsData.map((sfx) => (
                  <div
                    key={`sfx-${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_word_idx}`}
                    id={`sfx-${sfx.dialogue_index}-${sfx.start_word_idx}-${sfx.end_word_idx}`}
                    className={`sfx-description ${
                      selectedSoundEffect?.dialogueIndex ===
                        sfx.dialogue_index && selectedSoundEffect?.sfx === sfx
                        ? "selected"
                        : ""
                    }`}
                  >
                    <div className="sfx-description-header">
                      <div className="sfx-header-left">
                        <span className="sfx-icon">🎵</span>
                        <span className="sfx-label">
                          Sound Effect {soundEffectsData.indexOf(sfx) + 1}
                        </span>
                      </div>
                      {!isReadOnly && (
                        <button
                          className="sfx-delete-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            const newSoundEffectsData = soundEffectsData.filter(
                              (s) => s !== sfx,
                            );
                            setSoundEffectsData(newSoundEffectsData);
                            if (selectedSoundEffect?.sfx === sfx) {
                              setSelectedSoundEffect(null);
                            }
                          }}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                    <p className="sfx-description-text">
                      <textarea
                        className="sfx-description-input"
                        value={sfx.foley_description}
                        disabled={isReadOnly}
                        onInput={(e) => {
                          e.target.style.height = "auto";
                          e.target.style.height = e.target.scrollHeight + "px";
                        }}
                        ref={(textarea) => {
                          if (textarea) {
                            textarea.style.height = "auto";
                            textarea.style.height =
                              textarea.scrollHeight + "px";
                          }
                        }}
                        onChange={(e) => {
                          const newSoundEffectsData = soundEffectsData.map(
                            (s) =>
                              s === sfx
                                ? { ...s, foley_description: e.target.value }
                                : s,
                          );
                          setSoundEffectsData(newSoundEffectsData);
                        }}
                      />
                    </p>
                    <p className="sfx-sentence">"{sfx.sentence}"</p>
                  </div>
                ))}
              </div>
            </div>
          </Panel>
        </PanelGroup>

        {/* Only show these if not in read-only mode */}
        {!isReadOnly && (
          <>
            {showSfxPopup && (
              <div
                className="sfx-popup"
                style={{
                  position: "fixed",
                  left: `${sfxPopupPosition.x}px`,
                  top: `${sfxPopupPosition.y}px`,
                }}
              >
                <button onClick={handleAddNewSfx}>🎵 Add SFX</button>
              </div>
            )}

            {showSfxDescriptionModal && (
              <div className="sfx-description-modal">
                <div className="modal-content">
                  <h3>Add Sound Effect</h3>
                  <div className="selected-text-container">
                    <p className="selected-text">
                      <span className="selected-text-label">
                        Selected text:
                      </span>
                      <span className="selected-text-content">
                        "{selectedText?.text}"
                      </span>
                    </p>
                  </div>
                  <textarea
                    value={newSfxDescription}
                    onChange={(e) => setNewSfxDescription(e.target.value)}
                    placeholder="Describe the sound effect..."
                  />
                  <div className="modal-actions">
                    <button
                      className="cancel-button"
                      onClick={() => {
                        setShowSfxDescriptionModal(false);
                        setNewSfxDescription("");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="confirm-button"
                      onClick={handleConfirmNewSfx}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  // Add these new state variables near the top of the component
  const [projects, setProjects] = useState([]);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);

  // Add this effect to load projects when email is available
  useEffect(() => {
    const loadProjects = async () => {
      if (!userEmail) return;

      setIsLoadingProjects(true);
      try {
        const response = await getUserProjects(userEmail);
        setProjects(response.projects);
      } catch (error) {
        console.error("Error loading projects:", error);
      } finally {
        setIsLoadingProjects(false);
      }
    };

    loadProjects();
  }, [userEmail]);

  // Add this function to handle project selection
  const handleProjectSelect = async (project) => {
    try {
      console.log("Loading private project with timestamp:", project.timestamp);

      // Clear all existing states first
      clearProjectStates();

      // Set timestamps
      const timestamp = project.timestamp;
      setSessionTimestamp(timestamp);
      setCurrentSessionTimestamp(timestamp);

      // Reset shared project flag
      setViewingSharedProject(null);
      setIsReadOnly(false);

      const response = await fetch(
        `${API_ENDPOINTS.GET_PROJECT_DATA}/${userEmail}/${timestamp}`,
      );
      const data = await response.json();

      // Track completed steps
      const newCompletedSteps = ["input"]; // Input is always completed for saved projects

      if (data.input_text) {
        setInputText(data.input_text);
      }

      if (data.screenplay) {
        setAlphaDialogues(data.screenplay);
        newCompletedSteps.push("screenplay");
      }

      if (data.voice_assignments) {
        const voiceAssignments = {};
        Object.entries(data.voice_assignments).forEach(
          ([character, voiceId]) => {
            const matchingVoice = voicePreviews.find(
              (voice) => voice.voice_id === voiceId,
            );
            if (matchingVoice) {
              voiceAssignments[character] = matchingVoice;
            }
          },
        );
        setCharacterVoiceAssignments(voiceAssignments);
        newCompletedSteps.push("voice-assignment");
      }

      if (data.bgm_description) {
        setMusicDescription(data.bgm_description);
        newCompletedSteps.push("music-description");
      }

      if (data.sound_effects) {
        setSoundEffectsData(data.sound_effects);
        newCompletedSteps.push("sound-effects");
      }

      // Set flags for BGM and SFX if they exist
      const hasBGM = await checkBGMExists(userEmail, project.timestamp);
      const hasSFX = await checkSFXExists(userEmail, project.timestamp);
      setHasBGMGenerated(hasBGM);
      setHasSFXGenerated(hasSFX);

      // Set furthest view based on available data
      let furthest = "input";
      if (data.screenplay) furthest = "screenplay";
      if (data.voice_assignments) furthest = "voice-assignment";
      if (data.bgm_description) furthest += ",music-description";
      if (data.sound_effects) furthest += ",sound-effects";

      // Initialize multitrack if available
      if (await checkMultitrackExists(userEmail, project.timestamp)) {
        setAlphaMultitrackPaths({});
        newCompletedSteps.push("multitrack-audio");
        setActiveView("multitrack-audio"); // Set to multitrack view if it exists
      } else {
        setActiveView("input"); // Default to input view if no multitrack
      }

      // Update completed steps
      setCompletedSteps(newCompletedSteps);
      setFurthestView(furthest);

      // Remove this line since we're handling setActiveView above
      // setActiveView("input");
    } catch (error) {
      console.error("Error loading project:", error);
      alert("Failed to load project. Please try again.");
    }
  };

  // Add this new helper function to clear all project-related states
  const clearProjectStates = () => {
    // Clear multitrack states
    if (alphaMultitrackRef) {
      alphaMultitrackRef.destroy();
    }
    setAlphaMultitrackRef(null);
    setAlphaMultitrackTracks([]);
    setAlphaMultitrackPaths(null);
    setAlphaCurrentTime(null);
    setIsPlayingAlphaMultitrack(false);

    // Clear screenplay and dialogue states
    setAlphaDialogues([]);
    setAlphaAlignmentData([]);

    // Clear voice assignments
    setCharacterVoiceAssignments({});

    // Clear BGM states
    setMusicDescription("");
    setHasBGMGenerated(false);

    // Clear SFX states
    setSoundEffectsData([]);
    setHasSFXGenerated(false);

    // Clear other states
    setCompletedSteps([]);
    setFurthestView("input");
    setQuotaError(null);
  };

  // Helper functions to check if files exist
  const checkBGMExists = async (email, timestamp, isShared = false) => {
    try {
      const response = await fetch(
        `${API_ENDPOINTS.GET_BGM_TRACK}/${email}/${timestamp}?is_shared=${isShared}`,
      );
      return response.ok;
    } catch {
      return false;
    }
  };

  const checkSFXExists = async (email, timestamp, isShared = false) => {
    try {
      const response = await fetch(
        `${API_ENDPOINTS.GET_SFX_TRACK}/${email}/${timestamp}?is_shared=${isShared}`,
      );
      return response.ok;
    } catch {
      return false;
    }
  };

  // Update the checkMultitrackExists function
  const checkMultitrackExists = async (email, timestamp, isShared = false) => {
    try {
      const response = await fetch(
        `${API_ENDPOINTS.GET_COMBINED_TRACK}/${email}/${timestamp}?is_shared=${isShared}`,
      );
      return response.ok;
    } catch {
      return false;
    }
    };

  // Add new state variables
  const [communityProjects, setCommunityProjects] = useState([]);
  const [isLoadingCommunity, setIsLoadingCommunity] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);

  // Add new state for sharing status
  const [isSharing, setIsSharing] = useState(false);

  // Add new function to handle sharing
  const handleShareProject = async () => {
    try {
      setIsSharing(true); // Start sharing state
      console.log("Sharing project...");
      console.log("currentUserEmail:", currentUserEmail);
      console.log("currentSessionTimestamp:", currentSessionTimestamp);

      if (!currentUserEmail || !currentSessionTimestamp) {
        alert("Cannot share project - session information missing");
        return;
      }

      await shareProject(currentUserEmail, currentSessionTimestamp);

      console.log("Project shared successfully!");
      // Refresh community projects
      await loadCommunityProjects();

      // Switch to community view
      setActiveView("community");
    } catch (error) {
      console.error("Error sharing project:", error);
      alert("Failed to share project. Please try again.");
    } finally {
      setIsSharing(false); // End sharing state
    }
  };

  // Add state for tracking reaction loading
  const [loadingReactions, setLoadingReactions] = useState({});

  // Update handleReaction
  const handleReaction = async (projectEmail, projectTimestamp) => {
    try {
      // Set loading state for this specific project
      setLoadingReactions((prev) => ({
        ...prev,
        [`${projectEmail}-${projectTimestamp}`]: true,
      }));

      const response = await addReaction(
        currentUserEmail,
        projectEmail,
        projectTimestamp,
        "thumbsUp",
      );

      if (response.success) {
        // Update the local state to reflect the change
        setCommunityProjects((prevProjects) =>
          prevProjects.map((project) => {
            if (
              project.email === projectEmail &&
              project.timestamp === projectTimestamp
            ) {
              return {
                ...project,
                reactions: response.reactionCount,
                hasReacted: response.hasReacted,
              };
            }
            return project;
          }),
        );
      }
    } catch (error) {
      console.error("Error adding reaction:", error);
      alert("Failed to add reaction. Please try again.");
    } finally {
      // Clear loading state for this project
      setLoadingReactions((prev) => ({
        ...prev,
        [`${projectEmail}-${projectTimestamp}`]: false,
      }));
    }
  };

  // Add function to load community projects
  const loadCommunityProjects = async () => {
    setIsLoadingCommunity(true);
    try {
      const response = await getCommunityProjects(currentUserEmail);
      setCommunityProjects(response.projects);
    } catch (error) {
      console.error("Error loading community projects:", error);
    } finally {
      setIsLoadingCommunity(false);
    }
  };

  // Add useEffect to load community projects
  useEffect(() => {
    if (activeView === "community") {
      loadCommunityProjects();
    }
  }, [activeView]);

  // Add this function after other handler functions
  const handleCommunityProjectSelect = async (project) => {
    try {
      console.log("Loading shared project:", project);

      // Clear all existing states first
      clearProjectStates();

      // Set read-only mode and store shared project info
      setIsReadOnly(true);
      setViewingSharedProject({
        email: project.email,
        timestamp: project.timestamp,
      });

      // Load project data
      const data = await getCommunityProject(project.email, project.timestamp);
      console.log(
        `(handleCommunityProjectSelect) debug 1 ${JSON.stringify(data)}`,
      );

      // Set input text if available
      if (data.input_text) {
        setInputText(data.input_text);
      }

      // Set states in specific order
      if (data.screenplay) {
        console.log("Setting screenplay data:", data.screenplay);
        setAlphaDialogues(data.screenplay);

        // Load word alignments using shared function
        try {
          const alignments = await loadWordAlignments(
            project.email,
            project.timestamp,
            data.screenplay,
            true, // isShared flag
          );
          setAlphaAlignmentData(alignments);
        } catch (error) {
          console.error("Error loading word alignments:", error);
        }
      }

      if (data.voice_assignments) {
        const voiceAssignments = {};
        Object.entries(data.voice_assignments).forEach(
          ([character, voiceId]) => {
            const matchingVoice = voicePreviews.find(
              (voice) => voice.voice_id === voiceId,
            );
            if (matchingVoice) voiceAssignments[character] = matchingVoice;
          },
        );
        setCharacterVoiceAssignments(voiceAssignments);
      }

      // Set BGM and SFX states
      if (data.bgm_description) {
        console.log("Setting BGM data");
        setMusicDescription(data.bgm_description);
        console.log(`(debug 2) hasBGMGenerated`);
        setHasBGMGenerated(
          await checkBGMExists(project.email, project.timestamp, true),
        );
      }

      if (data.sound_effects) {
        console.log("Setting SFX data");
        setSoundEffectsData(data.sound_effects);
        setHasSFXGenerated(
          await checkSFXExists(project.email, project.timestamp, true),
        );
      }

      // Initialize multitrack if available
      if (await checkMultitrackExists(project.email, project.timestamp, true)) {
        console.log("Shared project has multitrack, initializing...");
        setAlphaMultitrackPaths({});
        setActiveView("multitrack-audio");
      } else {
        // Start at input view to show the original text
        setActiveView("input");
        // setActiveView('multitrack-audio');
      }
    } catch (error) {
      console.error("Error loading community project:", error);
      alert("Failed to load project. Please try again.");
    }
  };

  return (
    <div className="alpha-demo-page">
      <img
        className="product-title"
        onClick={handleBackLanding}
        src="/audiowizard.png"
      />
      <div className="alpha-demo-message">
        <div className="title-line">
          <span className="explore">Explore</span>
          <span className="our">Our</span>
          <span className="highlight">Alpha Demo</span>
          <span className="separator">—</span>
        </div>
        <div className="subtitle-line">
          <span className="future">
            AI-Driven Audio Creation with Multicast TTS, Sound Effects, and
            Music
          </span>
        </div>
      </div>

      <div className="voice-exhibit-section">
        <div className="vertical-menu">
          <div className="menu-buttons">
            <button
              className={`menu-item ${activeView === "input" ? "active" : ""}`}
              onClick={() => setActiveView("input")}
              disabled={!isViewAvailable("input")}
            >
              <div className="menu-content">
                <span className="menu-step">1</span>
                <span className="menu-icon">📝</span>
                <span className="menu-text">Input Text</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "screenplay" ? "active" : ""}`}
              onClick={() => setActiveView("screenplay")}
              disabled={!isViewAvailable("screenplay")}
            >
              <div className="menu-content">
                <span className="menu-step">2</span>
                <span className="menu-icon">📜</span>
                <span className="menu-text">Screenplay</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "voice-assignment" ? "active" : ""}`}
              onClick={() => setActiveView("voice-assignment")}
              disabled={!isViewAvailable("voice-assignment")}
            >
              <div className="menu-content">
                <span className="menu-step">3</span>
                <span className="menu-icon">🎯</span>
                <span className="menu-text">Voice Assignment</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "music-description" ? "active" : ""}`}
              onClick={() => setActiveView("music-description")}
              disabled={!isViewAvailable("music-description")}
            >
              <div className="menu-content">
                <span className="menu-step">4</span>
                <span className="menu-icon">🎵</span>
                <span className="menu-text">Background Music</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "sound-effects" ? "active" : ""}`}
              onClick={() => setActiveView("sound-effects")}
              disabled={!isViewAvailable("sound-effects")}
            >
              <div className="menu-content">
                <span className="menu-step">5</span>
                <span className="menu-icon">🔊</span>
                <span className="menu-text">Sound Effects</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "multitrack-audio" ? "active" : ""}`}
              onClick={() => setActiveView("multitrack-audio")}
              disabled={!isViewAvailable("multitrack-audio")}
            >
              <div className="menu-content">
                <span className="menu-icon">🎭</span>
                <span className="menu-text">Multitrack Audio</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "gallery" ? "active" : ""}`}
              onClick={() => setActiveView("gallery")}
            >
              <div className="menu-content">
                <span className="menu-icon">🎭</span>
                <span className="menu-text">Voice Gallery</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "projects" ? "active" : ""}`}
              onClick={() => setActiveView("projects")}
            >
              <div className="menu-content">
                <span className="menu-icon">📚</span>
                <span className="menu-text">My Projects</span>
              </div>
            </button>
            <button
              className={`menu-item ${activeView === "community" ? "active" : ""}`}
              onClick={() => setActiveView("community")}
            >
              <div className="menu-content">
                <span className="menu-icon">👥</span>
                <span className="menu-text">Community Showcase</span>
              </div>
            </button>
          </div>
          <div className="remaining-quota">Quota: {quota}</div>
        </div>

        <div className="voice-content">
          {activeView === "input" ? (
            <div className="text-input-view">
              <div className="text-input-header">
                <h3 className="text-input-title">📝 Input Your Text</h3>
                <button
                  className={`transform-button ${isTransforming ? "loading" : ""}`}
                  onClick={() => handleTransformTextToScreenplay(inputText)}
                  // Disable button if in read-only mode or if transforming
                  disabled={isReadOnly || isTransforming}
                >
                  {isTransforming ? (
                    <>
                      <div className="loading-spinner" />
                      <span className="button-text">Transforming...</span>
                    </>
                  ) : (
                    <>
                      <span className="transform-icon">✨</span>
                      <span className="transform-text">
                        Transform to Screenplay
                      </span>
                      <span className="transform-arrow">→</span>
                    </>
                  )}
                </button>
              </div>
              <div className="text-input-container">
                <textarea
                  value={inputText}
                  onChange={handleTextChange}
                  placeholder="Enter your text here (maximum 2000 words)..."
                  className="text-input-area"
                  maxLength={10000}
                  // Disable textarea in read-only mode
                  disabled={isReadOnly}
                />
                {textError && <div className="text-error">{textError}</div>}
                <div className="word-count">
                  Words:{" "}
                  {inputText.trim() ? inputText.trim().split(/\s+/).length : 0}{" "}
                  / {WORD_LIMIT}
                </div>
              </div>
            </div>
          ) : activeView === "screenplay" ? (
            <div className="alpha-screenplay-view">
              <div className="alpha-screenplay-header">
                <h3 className="alpha-screenplay-title">
                  📜 Generated Screenplay
                </h3>
                {!isReadOnly && (
                  <h4 className="alpha-screenplay-subtitle">
                    ✏️ Click to Modify
                  </h4>
                )}
                <button
                  className={`voice-assignment-button ${isAssigningVoices ? "loading" : ""}`}
                  onClick={handleVoiceAssignment}
                  disabled={isReadOnly}
                >
                  {isAssigningVoices ? (
                    <>
                      <div className="loading-spinner" />
                      <span className="button-text">Assigning Voices...</span>
                    </>
                  ) : (
                    <>
                      <span className="button-icon">🎭</span>
                      <span className="button-text">Assign Voices</span>
                      <span className="button-arrow">→</span>
                    </>
                  )}
                </button>
              </div>
              <div
                className="alpha-screenplay"
                ref={alphaTextContainerRef}
                key={refreshKey}
              >
                {alphaDialogues && alphaDialogues.length > 0 ? (
                  alphaDialogues.map((dialogue, dialogueIndex) => {
                    const [character, text] = Object.entries(dialogue)[0];
                    return (
                      <>
                        <div
                          key={dialogueIndex}
                          className="alpha-dialogue-item"
                        >
                          <div className="alpha-dialogue-header">
                            <input
                              type="text"
                              className="alpha-character"
                              defaultValue={
                                character.endsWith(":")
                                  ? character.slice(0, -1)
                                  : character
                              }
                              placeholder="Character name"
                              onChange={(e) => {
                                const newDialogues = [...alphaDialogues];
                                newDialogues[dialogueIndex] = {
                                  [e.target.value]:
                                    alphaDialogues[dialogueIndex][character],
                                };
                                setAlphaDialogues(newDialogues);
                                e.target.style.width = `${e.target.scrollWidth}px`;
                              }}
                              ref={(input) => {
                                if (input) {
                                  input.style.width = `${input.scrollWidth}px`;
                                }
                              }}
                              style={{
                                minWidth: "20px",
                              }}
                              disabled={isReadOnly}
                            />
                            {!isReadOnly && (
                              <button
                                className="alpha-dialogue-delete"
                                onClick={() => {
                                  // Save current scroll position
                                  const scrollPosition =
                                    alphaTextContainerRef.current.scrollTop;

                                  const newDialogues = [...alphaDialogues];
                                  newDialogues.splice(dialogueIndex, 1);
                                  setAlphaDialogues(newDialogues);
                                  setRefreshKey((prev) => prev + 1);

                                  // Restore scroll position after state updates
                                  requestAnimationFrame(() => {
                                    if (alphaTextContainerRef.current) {
                                      alphaTextContainerRef.current.scrollTop =
                                        scrollPosition;
                                    }
                                  });
                                }}
                                title="Delete dialogue"
                              >
                                ✕
                              </button>
                            )}
                          </div>
                          <textarea
                            className="alpha-dialogue-text"
                            defaultValue={text}
                            placeholder="Enter dialogue..."
                            title={isReadOnly ? "" : "✏️ Click to modify"}
                            onChange={(e) => {
                              const newDialogues = [...alphaDialogues];
                              newDialogues[dialogueIndex] = {
                                [character]: e.target.value,
                              };
                              setAlphaDialogues(newDialogues);
                              e.target.style.height = "auto";
                              e.target.style.height = `${e.target.scrollHeight}px`;
                            }}
                            ref={(textArea) => {
                              if (textArea) {
                                textArea.style.height = "auto";
                                textArea.style.height = `${textArea.scrollHeight}px`;
                              }
                            }}
                            style={{
                              height: "auto",
                              minHeight: "24px",
                              overflow: "hidden",
                            }}
                            disabled={isReadOnly}
                          />
                        </div>
                        {!isReadOnly && (
                          <button
                            className="alpha-dialogue-add-between"
                            onClick={() => {
                              const scrollPosition =
                                alphaTextContainerRef.current.scrollTop;
                              const newDialogues = [...alphaDialogues];
                              newDialogues.splice(dialogueIndex + 1, 0, {
                                "": "",
                              });
                              setAlphaDialogues(newDialogues);
                              setRefreshKey((prev) => prev + 1);
                              requestAnimationFrame(() => {
                                if (alphaTextContainerRef.current) {
                                  alphaTextContainerRef.current.scrollTop =
                                    scrollPosition;
                                }
                              });
                            }}
                            title="Add dialogue"
                          >
                            +
                          </button>
                        )}
                      </>
                    );
                  })
                ) : (
                  <div className="alpha-empty-screenplay">
                    Transform text to see the screenplay here
                  </div>
                )}
              </div>
            </div>
          ) : activeView === "voice-assignment" ? (
            <div className="alpha-voice-assignment-view">
              <div className="alpha-voice-assignment-header">
                <h3 className="alpha-voice-assignment-title">
                  🎯 Character Voice Assignment
                </h3>
                <button
                  className={`generate-voice-button ${isGeneratingMultitrack ? "loading" : ""}`}
                  onClick={handleGenerateVoice}
                  // Disable button if in read-only mode or if generating
                  disabled={isReadOnly || isGeneratingMultitrack}
                >
                  {isGeneratingMultitrack ? (
                    <>
                      <div className="loading-spinner" />
                      <span className="button-text">Generating...</span>
                    </>
                  ) : (
                    <>
                      <span className="button-icon">🔊</span>
                      <span className="button-text">Generate Voice</span>
                      <span className="button-arrow">→</span>
                    </>
                  )}
                </button>
              </div>
              <div className="alpha-voice-assignment-content">
                {Object.entries(characterVoiceAssignments).map(
                  ([character, voice]) => (
                    <div key={character} className="alpha-character-voice-card">
                      <div className="alpha-character-info">
                        <div className="alpha-character-avatar">
                          {character[0].toUpperCase()}
                        </div>
                        <h4 className="alpha-character-name">{character}</h4>
                      </div>

                      <div className="alpha-assigned-voice">
                        <div
                          className="alpha-voice-avatar"
                          style={{
                            backgroundColor: getColorFromSeed(voice.name),
                          }}
                        >
                          {voice.name[0]}
                        </div>
                        <div className="alpha-voice-details">
                          <h4>{voice.name}</h4>
                          <p>{voice.description}</p>
                          <div className="alpha-voice-tags">
                            {voice.labels &&
                              Object.entries(voice.labels).map(
                                ([key, value], i) => (
                                  <span key={i} className="alpha-voice-tag">
                                    {`${key}: ${value}`}
                                  </span>
                                ),
                              )}
                          </div>
                        </div>
                        <div className="alpha-voice-actions">
                          <button
                            className="alpha-preview-voice"
                            onClick={() => handlePlayVoice(voice)}
                          >
                            {isPlaying &&
                            selectedVoice?.filename === voice.filename ? (
                              <IoPauseCircle size={24} />
                            ) : (
                              <IoPlayCircle size={24} />
                            )}
                          </button>
                          <button
                            className="alpha-change-voice"
                            onClick={() =>
                              setActiveCharacterForVoice(character)
                            }
                          >
                            Change Voice
                          </button>
                        </div>
                      </div>
                    </div>
                  ),
                )}
              </div>
            </div>
          ) : activeView === "multitrack-audio" ? (
            <div className="alpha-multitrack-view">
              <PanelGroup direction="horizontal">
                <Panel minSize={20} defaultSize={25}>
                  {/* <div className="alpha-screenplay-panel"> */}
                  <div className="alpha-multitrack-screenplay-panel">
                    <div className="alpha-screenplay-header">
                      <h3 className="alpha-screenplay-title">📖 Screenplay</h3>
                      <div className="button-group">
                        <button
                          className={`generate-bgm-button ${isGeneratingBGM ? "loading" : ""}`}
                          onClick={handleGenerateBGMDescription}
                          // Disable button if in read-only mode or if generating
                          disabled={isReadOnly || isGeneratingBGM}
                        >
                          {isGeneratingBGM ? (
                            <>
                              <div className="loading-spinner" />
                              <span className="button-text">Generating...</span>
                            </>
                          ) : (
                            <>
                              <span className="button-icon">🎵</span>
                              <span className="button-text">BGM</span>
                              <span className="button-arrow">→</span>
                            </>
                          )}
                        </button>
                        <button
                          className={`generate-sfx-button ${isGeneratingSFX ? "loading" : ""}`}
                          onClick={handleGenerateSFXDescription}
                          // Disable button if in read-only mode or if generating
                          disabled={isReadOnly || isGeneratingSFX}
                        >
                          {isGeneratingSFX ? (
                            <>
                              <div className="loading-spinner" />
                              <span className="button-text">Generating...</span>
                            </>
                          ) : (
                            <>
                              <span className="button-icon">🔊</span>
                              <span className="button-text">Sound Effects</span>
                              <span className="button-arrow">→</span>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                    {renderAlphaMultitrackScreenplay()}
                  </div>
                </Panel>
                <PanelResizeHandle />
                <Panel minSize={20} defaultSize={25}>
                  <div className="alpha-multitrack-container">
                    <div className="alpha-multitrack-title-section">
                      <h3 className="alpha-multitrack-title">
                        🎼 Multitrack Audio
                      </h3>
                      <div className="alpha-multitrack-controls">
                        <button
                          className={`alpha-play-pause-button ${isPlayingAlphaMultitrack ? "playing" : ""}`}
                          onClick={handlePlayPauseAlphaMultitrack}
                        >
                          {isPlayingAlphaMultitrack ? <FaPause /> : <FaPlay />}
                        </button>

                        <div
                          className={`alpha-download-dropdown ${isDropdownOpen ? "active" : ""}`}
                        >
                          <button
                            className={`alpha-download-button ${isReadOnly ? "disabled" : ""}`}
                            onClick={() => !isReadOnly && setIsDropdownOpen(!isDropdownOpen)}
                            disabled={isReadOnly}
                          >
                            <span className="button-icon">⬇️</span>
                            <span className="button-text">Download</span>
                            <span className="dropdown-arrow">▼</span>
                          </button>
                          {!isReadOnly && isDropdownOpen && (
                            <div className="dropdown-content">
                              <button
                                onClick={() => {
                                  handleCombinedDownload();
                                  setIsDropdownOpen(false);
                                }}
                              >
                                Combined Track
                              </button>
                              <button
                                onClick={() => {
                                  handleSeparateDownload();
                                  setIsDropdownOpen(false);
                                }}
                              >
                                Separate Tracks
                              </button>
                            </div>
                          )}
                        </div>
                        <button
                          className={`alpha-share-button ${isSharing ? "loading" : ""}`}
                          onClick={handleShareProject}
                          disabled={isReadOnly || isSharing}
                        >
                          {isSharing ? (
                            <>
                              <div className="loading-spinner" />
                              <span className="button-text">Sharing...</span>
                            </>
                          ) : (
                            <>
                              <span className="button-icon">
                                <FaShare />
                              </span>
                              <span className="button-text">Share</span>
                            </>
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="alpha-multitrack-wrapper">
                      <div className="alpha-track-labels">
                        {alphaMultitrackTracks.map((track) => (
                          <div key={track.id} className="alpha-audio-label">
                            {track.id}
                          </div>
                        ))}
                      </div>
                      <div
                        ref={alphaMultitrackContainerRef}
                        style={{ width: "100%", height: "200px" }}
                      ></div>
                    </div>
                  </div>
                </Panel>
              </PanelGroup>
            </div>
          ) : activeView === "music-description" ? (
            <div className="text-input-view">
              <div className="text-input-header">
                <h3 className="text-input-title">
                  🎵 Background Music Description
                </h3>
                <button
                  className={`transform-button ${isGeneratingMusic ? "loading" : ""}`}
                  onClick={handleGenerateMusic}
                  disabled={
                    isReadOnly || isGeneratingMusic || !musicDescription.trim()
                  }
                >
                  {isGeneratingMusic ? (
                    <>
                      <div className="loading-spinner" />
                      <span className="button-text">Generating Music...</span>
                    </>
                  ) : (
                    <>
                      <span className="transform-icon">🎼</span>
                      <span className="transform-text">Generate Music</span>
                      <span className="transform-arrow">→</span>
                    </>
                  )}
                </button>
              </div>
              <div className="text-input-container">
                <textarea
                  value={musicDescription}
                  onChange={handleMusicDescriptionChange}
                  placeholder="Describe the background music you want (e.g., 'A gentle piano melody with soft strings, creating a peaceful and contemplative atmosphere...')"
                  className="text-input-area"
                  maxLength={1000}
                  disabled={isReadOnly}
                />
                <div className="word-count">
                  Characters: {musicDescription.length} / 1000
                </div>
              </div>
            </div>
          ) : activeView === "sound-effects" ? (
            renderSoundEffectsView()
          ) : activeView === "projects" ? (
            <div className="projects-view">
              <h3 className="projects-title">📚 My Projects</h3>
              {isLoadingProjects ? (
                <div className="projects-loading">Loading projects...</div>
              ) : (
                <div className="projects-grid">
                  {projects.map((project) => (
                    <div
                      key={project.timestamp}
                      className="project-card"
                      onClick={() => handleProjectSelect(project)}
                    >
                      <h4 className="project-title">{project.title}</h4>
                      <div className="project-date">
                        {new Date(
                          project.last_modified * 1000,
                        ).toLocaleDateString()}
                      </div>
                    </div>
                  ))}
                  {projects.length === 0 && (
                    <div className="no-projects">
                      No projects yet. Start by creating a new audiobook!
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : activeView === "community" ? (
            <div className="community-showcase">
              <h3 className="community-title">👥 Community Showcase</h3>
              {isLoadingCommunity ? (
                <div className="community-loading">
                  Loading shared projects...
                </div>
              ) : (
                <div className="community-grid">
                  {communityProjects.map((project) => (
                    <div
                      key={`${project.email}-${project.timestamp}`}
                      className="community-card"
                      onClick={() => handleCommunityProjectSelect(project)}
                    >
                      <h4 className="project-title">{project.title}</h4>
                      <div className="project-info">
                        <div className="project-details">
                          <span className="project-author">
                            {project.email}
                          </span>
                          <span className="project-date">
                            {new Date(project.timestamp).toLocaleDateString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              },
                            )}
                          </span>
                        </div>
                        <button
                          className={`reaction-button ${project.hasReacted ? "active" : ""}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleReaction(project.email, project.timestamp);
                          }}
                          disabled={
                            loadingReactions[
                              `${project.email}-${project.timestamp}`
                            ]
                          }
                        >
                          <FaThumbsUp />
                          <span className="reaction-count">
                            {project.reactions}
                          </span>
                        </button>
                      </div>
                    </div>
                  ))}
                  {communityProjects.length === 0 && (
                    <div className="no-projects">
                      No shared projects yet. Be the first to share your
                      creation!
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <>
              <h3 className="voice-exhibit-title">🎭🎭 Voice Gallery</h3>
              <div className="voice-search">
                <input
                  type="text"
                  placeholder="Search voices..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="voice-search-input"
                />
              </div>
              {!voicePreviewsLoaded ? (
                <div className="voice-loading">Loading voice previews...</div>
              ) : (
                <div className="voice-list">
                  {filteredVoices.map((voice, index) => (
                    <div key={index} className="voice-item">
                      <div
                        className="voice-avatar"
                        style={{
                          backgroundColor: getColorFromSeed(voice.name),
                        }}
                      >
                        {voice.name.charAt(0)}
                      </div>
                      <div className="voice-info">
                        <h4 className="voice-name">{voice.name}</h4>
                        <p className="voice-description">
                          {voice.description || "No description available"}
                        </p>
                        <div className="voice-tags">
                          {voice.labels &&
                            Object.entries(voice.labels).map(
                              ([key, value], i) => (
                                <span
                                  key={i}
                                  className="voice-tag"
                                >{`${key}: ${value}`}</span>
                              ),
                            )}
                        </div>
                      </div>
                      <button
                        className="play-voice-button"
                        onClick={() => handlePlayVoice(voice)}
                        title={
                          isPlaying &&
                          selectedVoice?.filename === voice.filename
                            ? "Pause"
                            : "Play"
                        }
                      >
                        {isPlaying &&
                        selectedVoice?.filename === voice.filename ? (
                          <IoPauseCircle size={24} />
                        ) : (
                          <IoPlayCircle size={24} />
                        )}
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {/* Voice Selection Modal */}
      {activeCharacterForVoice && (
        <div className="alpha-voice-selection-modal">
          <div className="alpha-modal-content">
            <div className="alpha-modal-header">
              <h3>Select Voice for {activeCharacterForVoice}</h3>
              <button
                className="alpha-modal-close"
                onClick={() => {
                  setActiveCharacterForVoice(null);
                  setModalSearchQuery("");
                }}
              >
                ×
              </button>
            </div>

            <div className="alpha-modal-search">
              <input
                type="text"
                placeholder="Search voices by name or characteristics..."
                value={modalSearchQuery}
                onChange={(e) => {
                  console.log("Search query changed:", e.target.value);
                  setModalSearchQuery(e.target.value);
                }}
                className="alpha-modal-search-input"
              />
            </div>

            <div className="alpha-modal-voices">
              {filteredModalVoices.map((voice) => (
                <div
                  key={voice.voice_id}
                  className={`alpha-modal-voice-item ${
                    characterVoiceAssignments[activeCharacterForVoice]?.name ===
                    voice.name
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => {
                    setCharacterVoiceAssignments((prev) => ({
                      ...prev,
                      [activeCharacterForVoice]: voice,
                    }));
                    setActiveCharacterForVoice(null);
                    setModalSearchQuery("");
                  }}
                >
                  <div
                    className="alpha-modal-voice-avatar"
                    style={{ backgroundColor: getColorFromSeed(voice.name) }}
                  >
                    {voice.name[0]}
                  </div>
                  <div className="alpha-modal-voice-info">
                    <h4 className="alpha-modal-voice-name">{voice.name}</h4>
                    <p className="alpha-modal-voice-description">
                      {voice.description}
                    </p>
                    <div className="alpha-modal-voice-tags">
                      {voice.labels &&
                        Object.entries(voice.labels).map(([key, value], i) => (
                          <span key={i} className="alpha-modal-voice-tag">
                            {`${key}: ${value}`}
                          </span>
                        ))}
                    </div>
                  </div>
                  <button
                    className="alpha-modal-preview"
                    onClick={(e) => {
                      e.stopPropagation();
                      handlePlayVoice(voice);
                    }}
                  >
                    {isPlaying && selectedVoice?.filename === voice.filename ? (
                      <IoPauseCircle size={20} />
                    ) : (
                      <IoPlayCircle size={20} />
                    )}
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Place audio element here so it's accessible to all sections */}
      <audio
        ref={audioRef}
        onEnded={handleAudioEnded}
        style={{ display: "none" }}
      />
    </div>
  );
}
export default LandingPage;
