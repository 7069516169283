import { API_ENDPOINTS } from "./api";

export const transformTextToScreenplay = async (text, email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.TRANSFORM, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        text,
        email,
        timestamp,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during transformation:", error);
    throw error;
  }
};

/**
 * Subscribe the email to MailerLite and initialize the quota in the MongoDB.
 */
export const subscribeToMailerLite = async (subscribeEmail) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ email: subscribeEmail }),
  };

  try {
    const response = await fetch(API_ENDPOINTS.SUBSCRIBE, requestOptions);
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Subscription failed");
    }

    // The request was successful
    return {
      success: data.success,
      message: data.message,
    };
  } catch (error) {
    console.error("Subscription error:", error.message);
    throw new Error(error.message || "Failed to process subscription");
  }
};

// export const assignVoicesToCharacters = async (dialogues, email, timestamp) => {
export const assignVoicesToCharacters = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.ASSIGN_VOICES, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during voice assignment:", error);
    throw error;
  }
};

export const generateMultitrackAudio = async (
  dialogues,
  voiceAssignments,
  email,
  timestamp,
) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 10 * 60 * 1000); // 10 mins timeout

  try {
    const response = await fetch(API_ENDPOINTS.GENERATE_MULTITRACK, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        dialogues,
        voice_assignments: voiceAssignments,
        email,
        timestamp,
      }),
      signal: controller.signal,
    });

    clearTimeout(timeoutId); // Clear the timeout if request completes
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    clearTimeout(timeoutId); // Also clear timeout on error
    if (error.name === "AbortError") {
      console.error(
        "Request timed out but the generation might still be processing in the backend",
      );
      throw new Error(
        "Request timed out. Please check the results page in a few minutes.",
      );
    }
    console.error("Error generating multitrack audio:", error);
    throw error;
  }
};

export const getVoiceGeneration = async (email, timestamp, dialogueIndex, isShared = false) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_VOICE_GENERATION}/${email}/${timestamp}/${dialogueIndex}?is_shared=${isShared}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.blob();
  } catch (error) {
    console.error("Error getting voice generation:", error);
    throw error;
  }
};

export const getWordAlignment = async (email, timestamp, dialogueIndex, isShared = false) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_WORD_ALIGNMENT}/${email}/${timestamp}/${dialogueIndex}?is_shared=${isShared}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error getting word alignment:", error);
    throw error;
  }
};

export const getMultitrack = async (
  email,
  timestamp,
  character,
  isShared = false,
) => {
  try {
    const encodedCharacter = encodeURIComponent(character);
    const url = `${API_ENDPOINTS.GET_MULTITRACK}/${email}/${timestamp}/${encodedCharacter}?is_shared=${isShared}`;

    console.log("Fetching multitrack from:", url);

    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.blob();
  } catch (error) {
    console.error("Error getting multitrack:", error);
    throw error;
  }
};

export const getCombinedTrack = async (email, timestamp, isShared = false) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_COMBINED_TRACK}/${email}/${timestamp}?is_shared=${isShared}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.blob();
  } catch (error) {
    console.error("Error getting combined track:", error);
    throw error;
  }
};

export const getSeparateTracksZip = async (email, timestamp) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_SEPARATE_TRACKS_ZIP}/${email}/${timestamp}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.blob();
  } catch (error) {
    console.error("Error getting separate tracks zip:", error);
    throw error;
  }
};

export const getRemainingQuota = async (email) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_REMAINING_QUOTA}?email=${encodeURIComponent(email)}`,
      requestOptions,
    );
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Get quota failed");
    }

    // The request was successful
    return {
      success: data.success,
      quota: data.quota,
    };
  } catch (error) {
    console.error("Get remaining quota error:", error.message);
    throw new Error(error.message || "Failed to get remaining quota");
  }
};

export const calculateQuotaUsage = async (dialogues) => {
  try {
    const response = await fetch(API_ENDPOINTS.CALCULATE_QUOTA_USAGE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dialogues: dialogues }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.quota_needed;
  } catch (error) {
    console.error("Error calculating quota usage:", error);
    throw error;
  }
};

export const subtractQuota = async (email, amount) => {
  try {
    const response = await fetch(API_ENDPOINTS.SUBTRACT_QUOTA, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, amount }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.success;
  } catch (error) {
    console.error("Error subtracting quota:", error);
    throw error;
  }
};

export const generateBGMDescription = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.GENERATE_BGM_DESCRIPTION, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.bgm_description;
  } catch (error) {
    console.error("Error generating BGM description:", error);
    throw error;
  }
};

export const generateBackgroundMusic = async (
  description,
  email,
  timestamp,
) => {
  try {
    const response = await fetch(API_ENDPOINTS.GENERATE_BGM, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        description,
        email,
        timestamp,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.success;
  } catch (error) {
    console.error("Error generating background music:", error);
    throw error;
  }
};

export const getBGMTrack = async (email, timestamp, isShared = false) => {
  try {
    console.log(`(debug super )${isShared}`)
    const response = await fetch(
      `${API_ENDPOINTS.GET_BGM_TRACK}/${email}/${timestamp}?is_shared=${isShared}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.blob();
  } catch (error) {
    console.error("Error getting BGM track:", error);
    throw error;
  }
};

export const generateSoundEffectsDescription = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.GENERATE_SFX_DESCRIPTION, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.sound_effects_dir;
  } catch (error) {
    console.error("Error generating sound effects description:", error);
    throw error;
  }
};

export const getSoundEffectsDescription = async (email, timestamp) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_SFX_DESCRIPTION}/${email}/${timestamp}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error getting sound effects description:", error);
    throw error;
  }
};

export const saveModifiedSoundEffects = async (
  email,
  timestamp,
  soundEffectsData,
) => {
  try {
    const response = await fetch(API_ENDPOINTS.SAVE_MODIFIED_SFX, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
        sound_effects_data: soundEffectsData,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to save modified sound effects");
    }

    return await response.json();
  } catch (error) {
    console.error("Error saving modified sound effects:", error);
    throw error;
  }
};

export const generateSoundEffects = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.GENERATE_SFX, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.success;
  } catch (error) {
    console.error("Error generating sound effects:", error);
    throw error;
  }
};

export const getSoundEffectsTrack = async (
  email,
  timestamp,
  isShared = false,
) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_SFX_TRACK}/${email}/${timestamp}?is_shared=${isShared}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.blob();
  } catch (error) {
    console.error("Error getting sound effects track:", error);
    throw error;
  }
};

export const saveModifiedScreenplay = async (screenplay, email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.SAVE_MODIFIED_SCREENPLAY, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        timestamp,
        screenplay: JSON.stringify(screenplay),
      }),
    });
    const data = await response.json();
    return data.success;
  } catch (error) {
    console.error("Error saving modified screenplay:", error);
    return false;
  }
};

export const getUserProjects = async (email) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.GET_USER_PROJECTS}/${email}`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching user projects:", error);
    throw error;
  }
};

export const shareProject = async (email, timestamp) => {
  try {
    const response = await fetch(API_ENDPOINTS.SHARE_PROJECT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, timestamp }),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error sharing project:", error);
    throw error;
  }
};

export const getCommunityProjects = async (userEmail) => {
  try {
    const url = `${API_ENDPOINTS.GET_COMMUNITY_PROJECTS}${userEmail ? `?userEmail=${encodeURIComponent(userEmail)}` : ""}`;
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching community projects:", error);
    throw error;
  }
};

export const getCommunityProject = async (email, timestamp) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.GET_COMMUNITY_PROJECT}/${email}/${timestamp}`,
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  } catch (error) {
    console.error("Error fetching community project:", error);
    throw error;
  }
};

export const addReaction = async (
  userEmail,
  projectEmail,
  projectTimestamp,
  reaction,
) => {
  try {
    const response = await fetch(API_ENDPOINTS.ADD_REACTION, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userEmail,
        projectEmail,
        projectTimestamp,
        reaction,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error adding reaction:", error);
    throw error;
  }
};
