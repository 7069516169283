const API_BASE_URL = "https://api.audiowizard.ai";
export const API_ENDPOINTS = {
  SUBSCRIBE: `${API_BASE_URL}/subscribe/`,
  TRANSFORM: `${API_BASE_URL}/screenplay`,
  ASSIGN_VOICES: `${API_BASE_URL}/assign-voices`,
  GENERATE_MULTITRACK: `${API_BASE_URL}/generate-multitrack`,
  GET_VOICE_GENERATION: `${API_BASE_URL}/get-voice-generation`,
  GET_WORD_ALIGNMENT: `${API_BASE_URL}/get-word-alignment`,
  GET_MULTITRACK: `${API_BASE_URL}/get-multitrack`,
  GET_COMBINED_TRACK: `${API_BASE_URL}/get-combined-track`,
  GET_SEPARATE_TRACKS_ZIP: `${API_BASE_URL}/get-separate-tracks-zip`,
  GET_REMAINING_QUOTA: `${API_BASE_URL}/get_quota/`,
  CALCULATE_QUOTA_USAGE: `${API_BASE_URL}/calculate-quota-usage`,
  SUBTRACT_QUOTA: `${API_BASE_URL}/subtract_quota/`,
  GENERATE_BGM_DESCRIPTION: `${API_BASE_URL}/generate-bgm-description`,
  GENERATE_BGM: `${API_BASE_URL}/generate-bgm`,
  GET_BGM_TRACK: `${API_BASE_URL}/get-bgm-track`,
  GENERATE_SFX_DESCRIPTION: `${API_BASE_URL}/generate-sfx-description`,
  GET_SFX_DESCRIPTION: `${API_BASE_URL}/get-sfx-description`,
  SAVE_MODIFIED_SFX: `${API_BASE_URL}/save-modified-sfx`,
  GENERATE_SFX: `${API_BASE_URL}/generate-sfx`,
  GET_SFX_TRACK: `${API_BASE_URL}/get-sfx-track`,
  SAVE_MODIFIED_SCREENPLAY: `${API_BASE_URL}/save_modified_screenplay`,
  GET_USER_PROJECTS: `${API_BASE_URL}/get-user-projects`,
  GET_PROJECT_DATA: `${API_BASE_URL}/get-project-data`,
  SHARE_PROJECT: `${API_BASE_URL}/share-project`,
  GET_COMMUNITY_PROJECTS: `${API_BASE_URL}/get-community-projects`,
  GET_COMMUNITY_PROJECT: `${API_BASE_URL}/get-community-project`,
  ADD_REACTION: `${API_BASE_URL}/add-reaction`,
};
